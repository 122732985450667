


function makeLegendFormatter(customSort) {
  function legendFormatter(data) {
    if (customSort && typeof customSort === 'function') {
      data.series = customSort(data.series)
    }
    if (data.x == null) {
      var html = ''
      // This happens when there's no selection and {legend: 'always'} is set.
      html += '<div class="series">'
      html += data.series
        .map(function (series) {
          return (
            `<b style='padding-right: 12px;'><span style='color: ${series.color}'>` + series.labelHTML + '</span></b>'
          )
        })
        .join(' ')
      html += '</div>'
      html += '<div class="legend-time">&nbsp;</div>'
      return html
    }

    var html = ''
    html += '<div class="series">'
    data.series.forEach(function (series) {
      if (!series.isVisible) return
      var labeledData =
        `<b style='padding-right: 12px;'><span style='color: ${series.color}'>` +
        series.labelHTML +
        `:</span> <span>` +
        series.yHTML +
        '</span></b>'
      html += labeledData
    })
    html += '</div>'
    // console.log('data.xHTML', data.xHTML) 
    // Final html is the date time. 2/17/2025, 1:31:00 AM or <div class="legend-time">2/17/2025, 1:31:00 AM</div>
    html += data.xHTML
    return html
  }

  return legendFormatter
}

export {
  makeLegendFormatter
}

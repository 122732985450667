import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { XPanel, PageTitle } from '../../components'
import CommentsSection from './Comments'
import Moment from 'moment'
import { onUserActivity } from '@analytics/activity-utils'
//import Dygraph from 'dygraphs';
import Dygraph from '../../../dygraphs'
import Spinner from '../../indicators/spinner.js'
import PastDatePick from './pastdatepick.js'
import DownloadCSVButton from './DownloadButton'
import { getRelayChartOptions } from './dygraph/relay-chart/options'
import { getCo2ChartOptions } from './dygraph/co2-chart/options'
import { temperatureChartOptions } from './dygraph/temp-chart/options'
import { volatileGasChartOptions } from './dygraph/volatile-gas-chart/options'
import { fineParticulatesChartOptions } from './dygraph/fine-particulates-chart/options'
import { formatCo2Data } from './dygraph/co2-chart'
import { buildTemperatureFromLiveStats, formatTemperatureChartData } from './dygraph/temp-chart/format'
import { buildCo2DataFromLiveStats } from './dygraph/co2-chart/format'
import { buildRelayDataFromLiveStats, formatRelayChartData } from './dygraph/relay-chart/format'
import { buildFineParticulatesFromLiveStats } from './dygraph/fine-particulates-chart/format'
import { buildVolatileGasFromLiveStats, formatVolatileGasChartData } from './dygraph/volatile-gas-chart/format'
import { getBulkData } from './util/fetch-streaming-data.js'
import { normalizeDateTimes } from './dygraph/utils/normalize-data'

import './dataanalysis.css'

import api from '../../util/api.js'
import { pressureChartOptions } from './dygraph/pressure-chart/options'
import { buildPressureFromLiveStats, formatPressureData } from './dygraph/pressure-chart/format'

const { getDevice } = api

// duty on all charts
const modeMap = {
  0: 'OFF',
  1: 'Ventilation', // Display max and min vent on right side of chart 0-100
  2: 'Economizer', // PPM high and ppm low on left side of chart 0-2500
  3: 'Actuator', // min vent and max vent on right side of chart 0-100 and co2 set point on left side of chart 0-2500 cfng_dcv."set_point"
}

const LIVE_VIEW_MODE = 'live'
const PAST_VIEW_MODE = 'past'
const LIVE_WINDOW_SIZE = 9

function logger(...message) {
  console.log(`~~~~~~~~~~~ ${new Date().toISOString()}`, ...message)
}

/* old mode map
const modeMap = {
  '1': 'Ventilation',
  '2': 'Economzier',
  '3': 'Actuator',
}
*/

function initialTimeStart() {
  return Moment().subtract(LIVE_WINDOW_SIZE, 'hours')
}

function initialTimeEnd() {
  return Moment()
}

let configCache

function DataAnalysis({ siteID, deviceID, deviceInfo }) {
  // Refs for graph elements
  const graphTempDiv = useRef(null)
  const graphRelayDiv = useRef(null)
  const graphRelayMasterDiv = useRef(null)
  const graphCo2Div = useRef(null)
  const graphVolatileGasDiv = useRef(null)
  const graphFineParticulatesDiv = useRef(null)
  const graphPressureDiv = useRef(null)
  const intervalRef = useRef(null)
  const cacheRef = useRef({
    initialStartTime: initialTimeStart(),
    initialEndTime: Moment(),
    currentStartTime: null,
    currentEndTime: null,
    live: {},
    past: {},
  })

  // Refs for graph instances
  const temperatureGraph = useRef()
  const relayGraph = useRef()
  const relayMasterGraph = useRef()
  const co2Graph = useRef()
  const vocGraph = useRef()
  const spsGraph = useRef()
  const pressureGraph = useRef()
  const sync = useRef()

  // State
  const [allData, setAllData] = useState([])
  const [parentDeviceId] = useState(getParentId())
  const [liveTempData, setLiveTempData] = useState([])
  const [pastTempData, setPastTempData] = useState([])
  const [liveCo2Data, setLiveCo2Data] = useState([])
  const [pastCo2Data, setPastCo2Data] = useState([])
  const [liveRelayData, setLiveRelayData] = useState([])
  const [pastRelayData, setPastRelayData] = useState([])
  const [liveMasterRelayData, setLiveMasterRelayData] = useState([])
  const [pastMasterRelayData, setPastMasterRelayData] = useState([])
  const [liveVolatileGasData, setLiveVolatileGasData] = useState([])
  const [pastVolatileGasData, setPastVolatileGasData] = useState([])
  const [liveFineParticulatesData, setLiveFineParticulatesData] = useState([])
  const [pastFineParticulatesData, setPastFineParticulatesData] = useState([])
  const [livePressureData, setLivePressureData] = useState([])
  const [pastPressureData, setPastPressureData] = useState([])
  const [apiCalling, setApiCalling] = useState(false)
  const [deviceRelayConfig, setDeviceRelayConfig] = useState({})
  const [deviceName, setDeviceName] = useState(null)
  const [deviceMac, setDeviceMac] = useState(null)
  const [deviceEt, setDeviceEt] = useState(30)
  const [co2Mode, setCo2Mode] = useState(null)
  const [co2_cnfg_dcv, setCo2CnfgDcv] = useState({})
  const [ startTime, setStartTime] = useState(cacheRef.current.initialStartTime)
  const [ endTime, setEndTime] = useState(cacheRef.current.initialEndTime)
  const [ viewMode, setViewMode ] = useState(LIVE_VIEW_MODE)
  const [allPastData, setAllPastData] = useState([])
  const [isExtender, setIsExtender] = useState(false)
  // Add isInitialLoadRef near other refs
  const isInitialLoadRef = useRef(true);
  const viewModeRef = useRef(LIVE_VIEW_MODE);

  // Add state to track initial load
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Add ref to track the last end time
  const lastEndTimeRef = useRef(null);

  const setGraphTempDiv = (element) => {
    graphTempDiv.current = element
  }

  const setGraphRelayDiv = (element) => {
    graphRelayDiv.current = element
  }

  const setGraphRelayMasterDiv = (element) => {
    graphRelayMasterDiv.current = element
  }

  const setGraphCo2Div = (element) => {
    graphCo2Div.current = element
  }

  // Non thermastat charts
  const setGraphVoCDiv = (element) => {
    graphVolatileGasDiv.current = element
  }

  const setGraphSpsDiv = (element) => {
    graphFineParticulatesDiv.current = element
  }

  const setGraphPressureDiv = (element) => {
    graphPressureDiv.current = element
  }

  useEffect(() => {
    console.log('useEffect ran', siteID, deviceID, deviceEt)
    //  Initial time parameters are declared and stored in state.
    
    /* Loading state */
    setApiCalling(true)

    /* Get device configuration, then live data */
    getDevice(siteID, deviceID).then((res) => {
      const device = (res.data || {}).device
      let deviceConfig = (device || {}).configuration
      //  Get Live Data and draw graphs.
      //  Also, set up the interval by which we will continue to get Live Data and draw graphs.

      console.log('~~~~~~~~~~~ configuration is: ', res.data.device.configuration)
      if (!deviceConfig.hasOwnProperty('et')) {
        console.log('configuration is missing et, setting to 60')
        deviceConfig.et = 60
      }
      
      setDeviceName(deviceConfig.lbl || device.name)
      setDeviceMac(device.mac_address)
      setDeviceEt(Number(deviceConfig.et))

      /* Enable poller */
      enablePoller(deviceEt)

      if (deviceConfig.co2_cnfg_dcv) {
        setCo2CnfgDcv(deviceConfig.co2_cnfg_dcv)
        if (deviceConfig.co2_cnfg_dcv.mode) {
          setCo2Mode(deviceConfig.co2_cnfg_dcv.mode)
        }
      }

      setApiCalling(false)
    })

    const FIVE_MIN = 300e3
    const idleListener = onUserActivity({
      // timeout: FIVE_MIN,
      // timeout: 30000,
      timeout: 10000,
      throttle: 3000,
      onIdle: (activeTime, event) => {
        console.log(`user idle! After active time of ${activeTime}`, event)
        disablePoller()
      },
      onWakeUp: (idleTimeInSeconds, event) => {
        console.log(`Wake up! Current View mode: ${viewModeRef.current}. Idle for ${idleTimeInSeconds} seconds`)

        if (viewModeRef.current !== LIVE_VIEW_MODE) {
          return
        }

        const moreThan12HoursHavePassed = idleTimeInSeconds > 12 * 60 * 60
        // If more than 12 hours has elapsed, reset the start time to 12 hours ago
        if (moreThan12HoursHavePassed) {
          // TODO: Reset the start time to 12 hours ago
        }

        /* Re-enable poller */
        enablePoller(deviceEt)
      },
      onHeartbeat: (timeActive, event) => {
        console.log(`user active for ${timeActive} seconds`, event)
      },
    })

    return () => {
      if (idleListener) {
        idleListener.disable()
      }
      disablePoller()
    }
  }, [siteID, deviceID, deviceEt])

  function disablePoller() {
    if (intervalRef.current) {
      console.log('disablePoller')
      intervalRef.current = clearInterval(intervalRef.current)
    }
  }

  function enablePoller(et) {
    console.log('enablePoller viewMode', viewModeRef.current)
    disablePoller()

    if (viewModeRef.current !== LIVE_VIEW_MODE) {
      return
    }
    console.log('enablePoller')
    /* Make initial request, then poll */
    getLiveDataAndDrawGraphs()
    const Refresh_Interval = et * 1000
    console.log('SET INTERVAL 2', Refresh_Interval)
    intervalRef.current = setInterval(getLiveDataAndDrawGraphs, Refresh_Interval)
  }

  const handleChangeViewMode = (newMode) => {
    console.log('handleChangeViewMode', newMode)
    viewModeRef.current = newMode
    setViewMode(newMode)

    if (newMode === LIVE_VIEW_MODE) {
      // setIsInitialLoad(true); // Reset initial load state when switching back to live mode
      // lastEndTimeRef.current = null // Reset the last end time
      drawAllGraphs({
        tempData: liveTempData,
        relayData: liveRelayData,
        relayMasterData: liveMasterRelayData,
        co2Data: liveCo2Data,
        vocData: liveVolatileGasData,
        spsData: liveFineParticulatesData,
        co2ChartOptions: {
          mode: co2Mode,
        },
        shouldRedraw: true,
        caller: 'handleChangeViewMode live',
      })

      /* Enable live data polling */
      enablePoller(deviceEt)

    } else if (newMode === PAST_VIEW_MODE) {
      drawAllGraphs({
        tempData: pastTempData,
        relayData: pastRelayData,
        relayMasterData: pastMasterRelayData,
        co2Data: pastCo2Data,
        vocData: pastVolatileGasData,
        spsData: pastFineParticulatesData,
        co2ChartOptions: {
          mode: co2Mode,
        },
        shouldRedraw: true,
        caller: 'handleChangeViewMode past',
      })

      /* Disable live data polling */
      disablePoller()
    }
  }


function getLiveDataAndDrawGraphs(startTime, endTime) {
  console.log('getLiveDataAndDrawGraphs ran')
  const isNonThermaStat = checkIfNonThermastat(deviceInfo, allData)
  setApiCalling(true)

  const key = 'live'
  // Use ref to track initial load state
  const isInitialLoad = isInitialLoadRef.current
  if (isInitialLoad) {
    isInitialLoadRef.current = false
  }

  // Calculate time window based on whether this is initial load or update
  const newEndTime = Moment()
  let useStartTime
  let useEndTime
  if (isInitialLoad) {
    useStartTime = initialTimeStart()
    useEndTime = newEndTime
  } else {
    // Ensure that starttime is not greater than 12 hours ago
    const twelveHoursAgo = Moment(newEndTime).subtract(LIVE_WINDOW_SIZE, 'hours')
    useStartTime = lastEndTimeRef.current
    console.log('wake up')
    console.log('useStartTime', useStartTime)
    console.log('twelveHoursAgo', twelveHoursAgo)
    
    // If last end time is more than 12 hours ago, reset to 12 hours
    if (useStartTime.isBefore(twelveHoursAgo)) {
      useStartTime = twelveHoursAgo
    }
    
    useEndTime = newEndTime
  }

  // Store the current end time for next update
  lastEndTimeRef.current = newEndTime;

  let parentData = new Promise((resolve) => resolve({ data: [] }))
  if (parentDeviceId) {
    /* Use parent mac ID to get parent data */
    parentData = getBulkData({
      siteId: siteID,
      deviceId: parentDeviceId,
      start: useStartTime,
      end: useEndTime,
    })
  }

  /* Use device mac ID to get device data */
  const deviceData = getBulkData({
    siteId: siteID,
    deviceId: deviceID,
    start: useStartTime,
    end: useEndTime,
  })

  Promise.all([deviceData, parentData])
    .then((results) => {
      setApiCalling(false)

      const res = normalizeDateTimes(results[0])
      const parentRes = normalizeDateTimes(results[1])

      let lastItem = res.data[res.data.length - 1]
      if (!lastItem && configCache) {
        lastItem = configCache
      }
      if (lastItem) {
        configCache = lastItem
      }
      const lastItemCnf = (lastItem || {}).cnfg_dcv

      // Process all the new data first
      const newCo2Data = buildCo2DataFromLiveStats(res.data)
      const newVocData = deviceInfo?.dt && isNonThermaStat ? buildVolatileGasFromLiveStats(res.data) : []
      const newSpsData = deviceInfo?.dt && isNonThermaStat ? buildFineParticulatesFromLiveStats(res.data) : []
      const newTempData = buildTemperatureFromLiveStats(res.data)
      const newRelayData = buildRelayDataFromLiveStats(res.data, deviceRelayConfig)
      const newMasterRelayData = buildRelayDataFromLiveStats(parentRes.data, deviceRelayConfig)

      // Add pressure data processing
      const newPressureData = buildPressureFromLiveStats(res.data)

      // Update all state at once
      Promise.all([
        setLiveCo2Data((liveCo2Data) => {
          cacheRef.current[key].co2Data = combineData(liveCo2Data, newCo2Data)
          cacheRef.current[key].chartDetails = getCo2ChartOptions(lastItemCnf, cacheRef.current[key].co2Data)
          return cacheRef.current[key].co2Data
        }),
        setLiveVolatileGasData((liveVolatileGasData) => {
          cacheRef.current[key].volatileGasData = combineData(liveVolatileGasData, newVocData)
          return cacheRef.current[key].volatileGasData
        }),
        setLiveFineParticulatesData((liveFineParticulatesData) => {
          cacheRef.current[key].fineParticulatesData = combineData(liveFineParticulatesData, newSpsData)
          return cacheRef.current[key].fineParticulatesData
        }),
        setLiveTempData((liveTempData) => {
          cacheRef.current[key].tempData = combineData(liveTempData, newTempData)
          return cacheRef.current[key].tempData
        }),
        setLiveRelayData((liveRelayData) => {
          cacheRef.current[key].relayData = combineData(liveRelayData, newRelayData)
          return cacheRef.current[key].relayData
        }),
        setLiveMasterRelayData((liveMasterRelayData) => {
          cacheRef.current[key].masterRelayData = combineData(liveMasterRelayData, newMasterRelayData)
          return cacheRef.current[key].masterRelayData
        }),
        setLivePressureData((livePressureData) => {
          console.log('livePressureData', livePressureData)
          console.log('newPressureData', newPressureData)
          cacheRef.current[key].pressureData = combineData(livePressureData, newPressureData)
          return cacheRef.current[key].pressureData
        }),
        setAllData((prevAllData) => {
          const newAllData = prevAllData.concat(res.data)
          cacheRef.current.allData = newAllData
          return newAllData
        }),
        setCo2Mode(() => cacheRef.current[key].chartDetails.mode),
        setCo2CnfgDcv(() => cacheRef.current[key].chartDetails.co2_cnfg_dcv),
      ]).then(() => {
        // Update time window after all other state updates
        setStartTime(useStartTime)
        setEndTime(useEndTime)

        cacheRef.current.currentStartTime = useStartTime
        cacheRef.current.currentEndTime = useEndTime

        setIsExtender((isExtender) => {
          console.log('What is isExtender', isExtender)
          const isExtenderDev = isExtenderDevice(deviceInfo, cacheRef.current.allData)
          cacheRef.current.isExtender = isExtenderDev
          return isExtenderDev
        })
        
        // Draw graphs after all state is updated
        drawAllGraphs({
          tempData: cacheRef.current[key].tempData,
          relayData: cacheRef.current[key].relayData,
          relayMasterData: cacheRef.current[key].masterRelayData,
          co2Data: cacheRef.current[key].co2Data,
          vocData: cacheRef.current[key].volatileGasData,
          spsData: cacheRef.current[key].fineParticulatesData,
          co2ChartOptions: cacheRef.current[key].chartDetails,
          pressureData: cacheRef.current[key].pressureData,
          caller: 'getLiveDataAndDrawGraphs',
          shouldRedraw: true,
        })
      })
    })
    .catch((error) => {
      console.error('One or both promises rejected:', error)
      setApiCalling(false)
      throw error
    })
  }


  function getPastDataAndDrawGraphs(startTime, endTime) {
    const isNonThermaStat = checkIfNonThermastat(deviceInfo, allData)
    setApiCalling(true)

    /* Use parent mac ID to get parent data */
    const parentData = !parentDeviceId
      ? new Promise((resolve) => resolve({ data: [] }))
      : getBulkData({
          siteId: siteID,
          deviceId: parentDeviceId,
          start: startTime,
          end: endTime,
        })

    /* Use device mac ID to get device data */
    const deviceData = getBulkData({
      siteId: siteID,
      deviceId: deviceID,
      start: startTime,
      end: endTime,
    })

    Promise.all([deviceData, parentData])
      .then((results) => {
        const res = normalizeDateTimes(results[0])
        const parentRes = normalizeDateTimes(results[1])

        let lastItem = res.data[res.data.length - 1]
        if (!lastItem && configCache) {
          lastItem = configCache
        }
        if (lastItem) {
          configCache = lastItem
        }
        const lastItemCnf = (lastItem || {}).cnfg_dcv

        // Process all the new data first
        const newCo2Data = buildCo2DataFromLiveStats(res.data)
        const newVocData = deviceInfo?.dt && isNonThermaStat ? buildVolatileGasFromLiveStats(res.data) : []
        const newSpsData = deviceInfo?.dt && isNonThermaStat ? buildFineParticulatesFromLiveStats(res.data) : []
        const newTempData = buildTemperatureFromLiveStats(res.data)
        const newRelayData = buildRelayDataFromLiveStats(res.data, deviceRelayConfig)
        const newMasterRelayData = buildRelayDataFromLiveStats(parentRes.data, deviceRelayConfig)
        const newPressureData = buildPressureFromLiveStats(res.data)

        // Get chart options
        const chartDetails = getCo2ChartOptions(lastItemCnf, newCo2Data)

        // Update all state at once
        Promise.all([
          setPastCo2Data((pastCo2Data) => {
            console.log('pastCo2Data', pastCo2Data)
            console.log('newCo2Data', newCo2Data)
            cacheRef.current.past.co2Data = newCo2Data
            return cacheRef.current.past.co2Data
          }),
          setPastVolatileGasData((pastVolatileGasData) => {
            console.log('pastVolatileGasData', pastVolatileGasData)
            console.log('newVocData', newVocData)
            cacheRef.current.past.volatileGasData = newVocData
            return cacheRef.current.past.volatileGasData
          }),
          setPastFineParticulatesData((pastFineParticulatesData) => {
            console.log('pastFineParticulatesData', pastFineParticulatesData)
            console.log('newSpsData', newSpsData)
            cacheRef.current.past.fineParticulatesData = newSpsData
            return cacheRef.current.past.fineParticulatesData
          }),
          setPastTempData((pastTempData) => {
            console.log('pastTempData', pastTempData)
            console.log('newTempData', newTempData)
            cacheRef.current.past.tempData = newTempData
            return cacheRef.current.past.tempData
          }),
          setPastRelayData((pastRelayData) => {
            console.log('pastRelayData', pastRelayData)
            console.log('newRelayData', newRelayData)
            cacheRef.current.past.relayData = newRelayData
            return cacheRef.current.past.relayData
          }),
          setPastMasterRelayData((pastMasterRelayData) => {
            console.log('pastMasterRelayData', pastMasterRelayData)
            console.log('newMasterRelayData', newMasterRelayData)
            cacheRef.current.past.masterRelayData = newMasterRelayData
            return cacheRef.current.past.masterRelayData
          }),
          setPastPressureData((pastPressureData) => {
            console.log('pastPressureData', pastPressureData)
            console.log('newPressureData', newPressureData)
            cacheRef.current.past.pressureData = newPressureData
            return cacheRef.current.past.pressureData
          }),
          setAllPastData((prevAllPastData) => prevAllPastData.concat(res.data)),
          setCo2Mode(chartDetails.mode),
          setCo2CnfgDcv(chartDetails.co2_cnfg_dcv),
        ]).then(() => {
          // Draw graphs after all state is updated
          drawAllGraphs({
            tempData: cacheRef.current.past.tempData,
            relayData: cacheRef.current.past.relayData,
            relayMasterData: cacheRef.current.past.masterRelayData,
            co2Data: cacheRef.current.past.co2Data,
            vocData: cacheRef.current.past.volatileGasData,
            spsData: cacheRef.current.past.fineParticulatesData,
            pressureData: cacheRef.current.past.pressureData,
            co2ChartOptions: chartDetails,
            caller: 'getPastDataAndDrawGraphs',
            shouldRedraw: true,
          })
          setApiCalling(false)
        })
      })
      .catch((error) => {
        console.error('One or both promises rejected:', error)
        setApiCalling(false)
        throw error
      })
  }

  const removeAnomalousData = (dataArr) => {
    console.log('dataanalysis removeAnomalousData() dataArr: ', dataArr)
    let newDataArr = []

    if (dataArr.length > 1) {
      //Running lastGoodZoneTemp version of anomaly detection
      var lastGoodZoneTemp = dataArr[0].tstat_read_data.zone_temp
      for (let i = 1; i < dataArr.length - 1; i++) {
        //console.log(dataArr[i].tstat_read_data.zone_temp);

        //Check for under 40 f degrees zone temp anomaly
        try {
          var parsedZoneTemp = parseFloat(dataArr[i].tstat_read_data.zone_temp)
          if (parsedZoneTemp < 40) {
            continue
          }
        } catch (e) {
          console.log('error in dataanalysis removeAnomalousData() ', e)
          continue
        }

        //Check for zone temp anomaly by last good zone temp
        if (Math.abs(lastGoodZoneTemp - dataArr[i].tstat_read_data.zone_temp) >= /*30*/ 12) {
          //do nothing
          console.log('skipping anomaly data dataanalysis')
          continue
        }

        // lastGoodZoneTemp = dataArr[i].tstat_read_data.zone_temp;
        newDataArr.push(dataArr[i])
      }
    } else {
      newDataArr = dataArr
    }

    return newDataArr
  }

  function getTimeWindow(dataArr) {
    const firstItemTimestamp = dataArr[0][0]
    const lastItemTimestamp = dataArr[dataArr.length - 1][0]
    return [firstItemTimestamp, lastItemTimestamp]
  }

  function drawAllGraphs({
    tempData,
    relayData,
    relayMasterData,
    co2Data,
    vocData,
    spsData,
    pressureData,
    co2ChartOptions,
    shouldRedraw,
    caller,
  }) {
    console.log(`drawAllGraphs data ${caller}`, {
      tempData,
      relayData,
      relayMasterData,
      co2Data,
      vocData,
      spsData,
      pressureData,
      co2ChartOptions,
      shouldRedraw,
      caller,
    })

    let temperatureGraph, relayGraph, relayMasterGraph, co2Graph, vocGraph, spsGraph, pressureGraphInstance

    if (tempData?.length) {
      temperatureGraph = drawTemperatureGraph(tempData, shouldRedraw)
    }

    if (relayData?.length) {
      relayGraph = drawRelayGraph(relayData, shouldRedraw)
    }

    if (relayMasterData?.length) {
      relayMasterGraph = drawRelayMasterGraph(relayMasterData, shouldRedraw)
    }

    if (co2Data?.length) {
      co2Graph = drawCo2Graph(co2Data, co2ChartOptions, shouldRedraw)
    }

    if (vocData?.length) {
      vocGraph = drawVolatileGasGraph(vocData, shouldRedraw)
    }

    if (spsData?.length) {
      spsGraph = drawSpsGraph(spsData, shouldRedraw)
    }

    if (pressureData?.length) {
      pressureGraphInstance = drawPressureGraph(pressureData, shouldRedraw)
    }

    if (sync.current === undefined) {
      if (vocGraph && spsGraph && pressureGraphInstance) {
        let dataArray = []
        if (!temperatureGraph || !relayGraph) {
          dataArray = [co2Graph, vocGraph, spsGraph, pressureGraphInstance]
        } else {
          dataArray = [
            temperatureGraph,
            relayGraph,
            co2Graph,
            vocGraph,
            spsGraph,
            pressureGraphInstance,
          ]
        }
        sync.current = Dygraph.synchronize(dataArray, {
          zoom: true,
          selection: true,
          range: false,
        })
      } else {
        if (!temperatureGraph && !relayGraph && !co2Graph && !pressureGraphInstance) {
          return
        }
        console.log('syncing temperatureGraph, relayGraph, relayMasterGraph, co2Graph', parentDeviceId)
        const syncArray = parentDeviceId
          ? [temperatureGraph, relayGraph, relayMasterGraph, co2Graph]
          : [temperatureGraph, relayGraph, co2Graph]

        if (graphPressureDiv.current) {
          syncArray.push(pressureGraphInstance)
        }

        sync.current = Dygraph.synchronize(syncArray, {
          zoom: true,
          selection: true,
          range: false,
        })
      }
    }
  }

  function setMinHeight(divRef) {
    const minHeight = 380
    if (divRef.clientHeight < minHeight) {
      divRef.style.minHeight = `${minHeight}px`
    }
  }

  function drawTemperatureGraph(relevantDataArr, shouldRedraw) {
    let newRelevantDataArr = formatTemperatureChartData(relevantDataArr)
    // console.log('drawTemperatureGraph newRelevantDataArr', newRelevantDataArr)

    if (temperatureGraph.current === undefined) {
      let graphDiv = graphTempDiv.current
      temperatureGraph.current = new Dygraph(graphDiv, newRelevantDataArr, {
        ...temperatureChartOptions,
        strokeWidth: 1.2,
        axisLineColor: '#999999', 
        gridLineColor: '#b0b0b0',
        // labelsKMB: false,
        // yAxisLabelWidth: 0,
        layout: {
          width: '100%',
        },
        // axes: {
        //   y: {
        //     axisLabelFormatter: function(y) {
        //       // Add right blank space
        //       return y.toString() + '‎ '.repeat(2)
        //     }
        //   }
        // }
      })
      setMinHeight(graphDiv.parentElement)
    } else {
      // if (shouldRedraw) {
      //   temperatureGraph.current.resetZoom()
      // }

      const timeWindow = [cacheRef.current.initialStartTime.unix() * 1000, cacheRef.current.currentEndTime.unix() * 1000]
      console.log('drawTemperatureGraph timeWindow', timeWindow)
      temperatureGraph.current.updateOptions({
        file: newRelevantDataArr,
        dateWindow: getTimeWindow(newRelevantDataArr), 
        // dateWindow: timeWindow, 
      })
    }
    return temperatureGraph.current
  }

  function drawRelayGraph(relevantDataArr, shouldRedraw) {
    let newRelevantDataArr = formatRelayChartData(relevantDataArr)

    if (relayGraph.current === undefined) {
      let graphDiv = graphRelayDiv.current
      relayGraph.current = new Dygraph(graphDiv, newRelevantDataArr, getRelayChartOptions('legendDivRelay'))
    } else {
      // if (shouldRedraw) {
      //   relayGraph.current.resetZoom()
      // }
      
      relayGraph.current.updateOptions({
        file: newRelevantDataArr,
        dateWindow: getTimeWindow(newRelevantDataArr), 
      })
    }
    return relayGraph.current
  }

  function drawRelayMasterGraph(relevantDataArr, shouldRedraw) {
    let newRelevantDataArr = formatRelayChartData(relevantDataArr)

    if (relayMasterGraph.current === undefined) {
      let graphDiv = graphRelayMasterDiv.current
      relayMasterGraph.current = new Dygraph(graphDiv, newRelevantDataArr, getRelayChartOptions('legendDivRelayMaster'))
    } else {
      // if (shouldRedraw) {
      //   relayMasterGraph.current.resetZoom()
      // }
      relayMasterGraph.current.updateOptions({
        file: newRelevantDataArr,
        dateWindow: getTimeWindow(newRelevantDataArr), 
        // dateWindow: [cacheRef.current.initialStartTime, cacheRef.current.currentEndTime], 
      })
    }
    return relayMasterGraph.current
  }

  function drawVolatileGasGraph(relevantDataArr, shouldRedraw) {
    let newRelevantDataArr = formatVolatileGasChartData(relevantDataArr)

    if (vocGraph.current === undefined) {
      let graphDiv = graphVolatileGasDiv.current
      vocGraph.current = new Dygraph(graphDiv, newRelevantDataArr, volatileGasChartOptions)
    } else {
      // if (shouldRedraw) {
      //   vocGraph.current.resetZoom()
      // }
      vocGraph.current.updateOptions({
        file: newRelevantDataArr,
        dateWindow: getTimeWindow(newRelevantDataArr), 
        // dateWindow: [cacheRef.current.initialStartTime, cacheRef.current.currentEndTime], 
      })
    }
    return vocGraph.current
  }

  function drawSpsGraph(relevantDataArr, shouldRedraw) {
    let newRelevantDataArr = relevantDataArr.map((dataItem, i) => {
      let resultItem = []
      resultItem[0] = new Date(dataItem[0])
      resultItem[1] = parseFloat(dataItem[1])
      resultItem[2] = parseFloat(dataItem[2])
      return resultItem
    })
    if (spsGraph.current === undefined) {
      let graphDiv = graphFineParticulatesDiv.current
      spsGraph.current = new Dygraph(graphDiv, newRelevantDataArr, fineParticulatesChartOptions)
    } else {
      // if (shouldRedraw) {
      //   spsGraph.current.resetZoom()
      // }
      spsGraph.current.updateOptions({
        file: newRelevantDataArr,
        dateWindow: getTimeWindow(newRelevantDataArr), 
        // dateWindow: [cacheRef.current.initialStartTime, cacheRef.current.currentEndTime], 
      })
    }

    return spsGraph.current
  }

  function drawCo2Graph(relevantDataArr, chartOptions, shouldRedraw) {
    // console.log('drawCo2Graph mode', chartOptions)
    let newRelevantDataArr = formatCo2Data(relevantDataArr, chartOptions)
    
    if (co2Graph.current === undefined) {
      let graphDiv = graphCo2Div.current
      // console.log('DRAW co2 GRAPH', newRelevantDataArr)
      co2Graph.current = new Dygraph(graphDiv, newRelevantDataArr, chartOptions.options)
    } else {
      // if (shouldRedraw) {
      //   co2Graph.current.resetZoom()
      // }
      console.log('UPDATE co2 GRAPH', newRelevantDataArr)
      co2Graph.current.updateOptions({
        file: newRelevantDataArr,
        dateWindow: getTimeWindow(newRelevantDataArr), 
        // dateWindow: [cacheRef.current.initialStartTime, cacheRef.current.currentEndTime], 
      })
    }
    return co2Graph.current
  }

  function drawPressureGraph(relevantDataArr, shouldRedraw) {
    let formattedData = formatPressureData(relevantDataArr)
    console.log('drawPressureGraph formattedData', formattedData)

    if (!graphPressureDiv.current) {
      return
    }
    
    if (pressureGraph.current === undefined) {
      pressureGraph.current = new Dygraph(
        graphPressureDiv.current,
        formattedData,
        { 
          ...pressureChartOptions,
          zoomCallback: function(minDate, maxDate, yRanges) {
            console.log('Callback: zoomCallback')
            console.log('Callback: zoomCallback opts', minDate, maxDate, yRanges)
          },
          drawCallback: function(dygraph, isInitial) {
            // isInitial is true on first draw
            // false for subsequent updates
            console.log(`Callback: drawCallback Graph updated ${new Date().toISOString()} ${isInitial ? 'initial' : 'subsequent'}`)
          }
        }
      )
      // setTimeout(() => {
      //   pressureGraph.current.resetZoom()
      // }, 300)
    } else {

      if (pressureGraph.current) {
        pressureGraph.current.updateOptions({
          file: formattedData,
          dateWindow: getTimeWindow(formattedData), 
          // dateWindow: [cacheRef.current.initialStartTime, cacheRef.current.currentEndTime], 
        })
      }
      
    
      // if (shouldRedraw) {
      //   pressureGraph.current.resetZoom()
      // }
    }
    return pressureGraph.current
  }

  const isNonThermaStat = checkIfNonThermastat(deviceInfo, allData)

  let showTempGraph = {}
  let showRelayGraph = {}
  let showRelayMasterGraph = {}
  let showCo2Graph = {}
  let showVolatileGasGraph = {}
  let showParticulatesGraph = {}
  let showPressureGraph = {}

  let showThermaStatGridItem = { display: isNonThermaStat ? 'none' : 'initial' }
  let showNonThermaStatGridItem = { display: isNonThermaStat ? 'initial' : 'none' }
  // console.log('State allData bulk', allData)
  // console.log('liveTempData', liveTempData)
  console.log('isExtender', isExtender)

  if (isNonThermaStat) {
    showTempGraph = { display: 'none' }
    showRelayGraph = { display: 'none' }
    showRelayMasterGraph = { display: 'none' }
    showCo2Graph = { display: 'none' }
    showVolatileGasGraph = { display: 'initial' }
    showParticulatesGraph = { display: 'initial' }
    showPressureGraph = { display: 'none' }
  } else {
    showTempGraph = { display: 'initial' }
    showRelayGraph = { display: 'initial' }
    showRelayMasterGraph = { display: 'initial' }
    showCo2Graph = { display: 'initial' }
    showVolatileGasGraph = { display: 'none' }
    showParticulatesGraph = { display: 'none' }
    showPressureGraph = { display: 'initial' }
  }

  if (isExtender) {
    // console.log('isExtender', isExtender)
    showTempGraph = { display: 'none' }
    showRelayGraph = { display: 'none' }
    showRelayMasterGraph = { display: 'none' }
    showCo2Graph = { display: 'none' }
    showVolatileGasGraph = { display: 'none' }
    showParticulatesGraph = { display: 'none' }
    showPressureGraph = { display: 'initial' }
  }

  let renderMasterRelays
  if (parentDeviceId) {
    renderMasterRelays = (
      <Col md={6} sm={6} xs={12} style={showThermaStatGridItem}>
        <XPanel>
          <XPanel.Title title='Master Relays Over Time'></XPanel.Title>
          <XPanel.Content>
            <div id='legendDivRelayMaster' className='legend' />
            <div style={{ minHeight: '320px' }} ref={setGraphRelayMasterDiv} />
          </XPanel.Content>
        </XPanel>
      </Col>
    )
  }

  let pressureRender = (
    <Col md={6} sm={6} xs={12} style={showPressureGraph}>
      <XPanel>
        <XPanel.Title title={(
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            Pressure Over Time 
            <button 
              style={{ display: 'none' }}
              onClick={() => {
                pressureGraph.current.resetZoom()
              }}>
              reset
            </button>
          </div>
        )}
        >
        </XPanel.Title>
        <XPanel.Content>
          <div id='legendDivPressure' className='legend' />
          <div style={{ minHeight: '320px' }} ref={setGraphPressureDiv} />
        </XPanel.Content>
      </XPanel>
    </Col>
  )

  let pressureGraphRenderTop = (isExtender) ? pressureRender : null
  
  let pressureGraphRenderBottom = (!isNonThermaStat && !isExtender) ? pressureRender : null

  return (
    <div>
      <Row>
        <Col md={4} sm={6} xs={6}>
          <span style={{ fontSize: '2em', marginRight: '8px' }}>
            {deviceName ? deviceName : 'loading...'}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={4} xs={4}>
          <div>
            <b>Mac Address: {deviceName ? deviceMac : 'loading...'}</b>
          </div>
          <div>
            <b>Data Trending</b>
          </div>
        </Col>
        <Col md={4} sm={4} xs={4}>
          <PastDatePick
            viewMode={viewMode}
            handleChangeViewMode={handleChangeViewMode}
            getPastDataAndDrawGraphs={getPastDataAndDrawGraphs}
          />
        </Col>
        <Col md={4} sm={4} xs={4}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <DownloadCSVButton
              isLoading={apiCalling}
              data={allData}
              pastData={allPastData}
              macId={deviceMac}
              view={viewMode}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={12} style={showTempGraph}>
          <XPanel>
            <XPanel.Title title='Temperature Over Time'></XPanel.Title>
            <XPanel.Content>
              <div id='legendDivTemp' className='legend' />
              <div style={{ minHeight: '320px' }} ref={setGraphTempDiv}></div>
            </XPanel.Content>
          </XPanel>
        </Col>
        {pressureGraphRenderTop}
        <Col md={6} sm={6} xs={12}>
          <XPanel>
            <XPanel.Title title='Ventilation Over Time'></XPanel.Title>
            <XPanel.Content>
              <div style={{ display: 'flex' }}>
                {co2_cnfg_dcv && co2_cnfg_dcv.mode && (
                  <div style={{ marginRight: '8px' }}>Mode: {modeMap[co2_cnfg_dcv.mode]}</div>
                )}
                {co2_cnfg_dcv && co2_cnfg_dcv.low_ppm && (
                  <div style={{ marginRight: '8px' }}>Low PPM: {co2_cnfg_dcv.low_ppm}</div>
                )}
                {co2_cnfg_dcv && co2_cnfg_dcv.high_ppm && (
                  <div style={{ marginRight: '8px' }}>High PPM: {co2_cnfg_dcv.high_ppm}</div>
                )}
              </div>
              <div id='legendDivCo2' className='legend' />
              <div style={{ minHeight: '320px' }} ref={setGraphCo2Div} />
            </XPanel.Content>
          </XPanel>
        </Col>
        <Col md={6} sm={6} xs={12} style={showNonThermaStatGridItem}>
          <XPanel>
            <XPanel.Title title='Volatile gases over time'></XPanel.Title>
            <XPanel.Content>
              <div id='legendDivVoc' className='legend' />
              <div style={{ minHeight: '320px' }} ref={setGraphVoCDiv} />
            </XPanel.Content>
          </XPanel>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} xs={12} style={showRelayGraph}>
          <XPanel>
            <XPanel.Title title='Relays Over Time'></XPanel.Title>
            <XPanel.Content>
              <div id='legendDivRelay' className='legend' />
              <div style={{ minHeight: '320px' }} ref={setGraphRelayDiv} />
            </XPanel.Content>
          </XPanel>
        </Col>
        {renderMasterRelays}
        <Col md={6} sm={6} xs={12} style={showParticulatesGraph}>
          <XPanel>
            <XPanel.Title title='Fine particulates over time'></XPanel.Title>
            <XPanel.Content>
              <div id='legendDivSps' className='legend' />
              <div style={{ minHeight: '320px' }} ref={setGraphSpsDiv} />
            </XPanel.Content>
          </XPanel>
        </Col>
        {pressureGraphRenderBottom}
      </Row>
      <CommentsSection itemId={deviceMac} />
    </div>
  )
}


function isExtenderDevice(deviceInfo, allData = []) {
  console.log('isExtenderDevice allData', allData)
  const lastItem = (allData && allData.length > 0) ? allData[allData.length - 1] : null
  if (lastItem && lastItem.tstat_read_data && lastItem.tstat_read_data.live_zn_1 && 
    (lastItem.tstat_read_data.live_zn_1.p_diff || lastItem.tstat_read_data.live_zn_1.tp_diff)
  ) {
    return true
  }
}

function checkIfNonThermastat(deviceInfo, allData) {
  // console.log('deviceInfo', deviceInfo)
  const lastItem = (allData && allData.length > 0) ? allData[allData.length - 1] : null

  if (
    lastItem && lastItem.tstat_read_data && lastItem.tstat_read_data.live_zn_1 && 
    (lastItem.tstat_read_data.live_zn_1.p_diff || lastItem.tstat_read_data.live_zn_1.tp_diff)
    ) {
    // console.log('deviceInfo lastItem', lastItem)
    return false
  }

  return (
    (deviceInfo && deviceInfo.dt && deviceInfo.dt === 'AQ Indoor') ||
    (deviceInfo && deviceInfo.deviceType && deviceInfo.deviceType === 3)
  )
}

//  Input: Some array of data & some new array of matching data.
//  Output: One concatenated array, with the appropriate
//    number of items removed from the front.
//    The number of items removed from the front should match the number
//    added to the end.
function combineData(arrOld, arrNew) {
  // console.log('combineData arrOld.length', arrOld.length)
  // console.log('combineData arrNew.length', arrNew.length)
  let result = arrOld.concat(arrNew)
  if (arrOld.length > arrNew.length) {
    // result = result.slice(arrNew.length, result.length)
  }
  // console.log('combineData result.length', result.length)
  return result
}

/**
 * URL Regex pattern
 * @type {RegExp}
 */
const URL_REGEX = /^(https?)?(?:[\:\/]*)([a-z0-9\.-]*)(?:\:(\d+))?(\/[^?#]*)?(?:\?([^#]*))?(?:#(.*))?$/i

/**
 * @typedef {object} UrlDetails
 * @property {string} href
 * @property {string} protocol
 * @property {string} hostname
 * @property {string} port
 * @property {string} path
 * @property {string} search
 * @property {string} hash
 */

/**
 * Zero dependency backward compatible url parser
 * @param {string} url
 * @returns {UrlDetails}
 */
function parseUrl(url = '') {
  const match = url.match(URL_REGEX)
  return {
    href: match[0] || '',
    protocol: match[1] || '',
    hostname: match[2] || '',
    port: match[3] || '',
    pathname: match[4] || '',
    search: match[5] || '',
    hash: match[6] || '',
  }
}

function getParentId() {
  const originalUrl = parseUrl(window.location.href)
  const testUrl = `http://fake.com${originalUrl.hash}`
  const url = new URL(testUrl)

  // Get search parameters
  const searchParams = url.searchParams
  const parentDeviceId = searchParams.get('parent')
  return parentDeviceId
}

export default DataAnalysis

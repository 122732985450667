

function roundToNearestMinuteOr30Seconds(isoTimestamp) {
  const date = new Date(isoTimestamp);

  // Get seconds and milliseconds
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  // Total seconds (including fractions)
  const totalSeconds = seconds + milliseconds / 1000;

  // Determine whether to round down to 0 or 30, or up to the next minute
  if (totalSeconds < 15) {
    date.setSeconds(0, 0); // Round to the start of the minute
  } else if (totalSeconds < 45) {
    date.setSeconds(30, 0); // Round to the 30-second mark
  } else {
    date.setSeconds(0, 0); // Round to the start of the next minute
    date.setMinutes(date.getMinutes() + 1); // Increment the minute
  }

  return date.toISOString();
}

function normalizeDateTimes(res) {
  if (!res || !res.data) {
    return []
  }

  res.data = res.data.map((item) => {
    item.datetime = roundToNearestMinuteOr30Seconds(item.datetime)
    return item
  })

  return res
}

export {
  roundToNearestMinuteOr30Seconds,
  normalizeDateTimes
}

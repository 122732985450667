

const MAX_VENT = 'Max Vent'
const MIN_VENT = 'Min Vent'
const LOW_PPM = 'Low PPM'
const HIGH_PPM = 'High PPM'

const CO2_LABELS = [
  'Datetime', // 0
  'CO2 1', // 1
  'CO2 2', // 2
  'CO2 3', // 3
  'CO2 4', // 4
  'Duty', // 5
  'Set Point', // 6
  LOW_PPM, // 7
  HIGH_PPM, // 8
  MIN_VENT, // 9
  MAX_VENT, // 10
]

export {
  MAX_VENT,
  MIN_VENT,
  LOW_PPM,
  HIGH_PPM,
  CO2_LABELS,
}
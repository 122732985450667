

function buildTemperatureFromLiveStats(dataArr) {
  let result = []
  let previousValidValues = {
    zoneTemp: null,
    heatSet: null,
    coolSet: null,
    ext1: null,
    ext2: null,
    ext3: null,
    ext4: null
  }

  if (!dataArr || !dataArr.length) {
    return []
  }
  
  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Index 0 will hold datetime!

    // Zone Temperature
    let zoneTemp = parseFloat(dataArr[i].tstat_read_data.zone_temp)
    if (zoneTemp === 0 && previousValidValues.zoneTemp !== null) {
      zoneTemp = previousValidValues.zoneTemp
    }
    if (zoneTemp !== 0) {
      previousValidValues.zoneTemp = zoneTemp
    }
    relevantDataArr.push(zoneTemp)

    // Heat Setpoint
    let heatSet = parseFloat(dataArr[i].tstat_read_data.heat_set)
    if (heatSet === 0 && previousValidValues.heatSet !== null) {
      heatSet = previousValidValues.heatSet
    }
    if (heatSet !== 0) {
      previousValidValues.heatSet = heatSet
    }
    relevantDataArr.push(heatSet)

    // Cool Setpoint
    let coolSet = parseFloat(dataArr[i].tstat_read_data.cool_set)
    if (coolSet === 0 && previousValidValues.coolSet !== null) {
      coolSet = previousValidValues.coolSet
    }
    if (coolSet !== 0) {
      previousValidValues.coolSet = coolSet
    }
    relevantDataArr.push(coolSet)

    // External Sensor 1
    let ext1 = dataArr[i].tstat_read_data.sht31_t1 === '-' ? null : parseFloat(dataArr[i].tstat_read_data.sht31_t1)
    if (ext1 === 0 && previousValidValues.ext1 !== null) {
      ext1 = previousValidValues.ext1
    }
    if (ext1 !== 0 && ext1 !== null) {
      previousValidValues.ext1 = ext1
    }
    relevantDataArr.push(ext1)

    // External Sensor 2
    let ext2 = dataArr[i].tstat_read_data.sht31_t2 === '-' ? null : parseFloat(dataArr[i].tstat_read_data.sht31_t2)
    if (ext2 === 0 && previousValidValues.ext2 !== null) {
      ext2 = previousValidValues.ext2
    }
    if (ext2 !== 0 && ext2 !== null) {
      previousValidValues.ext2 = ext2
    }
    relevantDataArr.push(ext2)

    // External Sensor 3
    let ext3 = dataArr[i].tstat_read_data.sht31_t3 === '-' ? null : parseFloat(dataArr[i].tstat_read_data.sht31_t3)
    if (ext3 === 0 && previousValidValues.ext3 !== null) {
      ext3 = previousValidValues.ext3
    }
    if (ext3 !== 0 && ext3 !== null) {
      previousValidValues.ext3 = ext3
    }
    relevantDataArr.push(ext3)

    // External Sensor 4
    let ext4 = dataArr[i].tstat_read_data.sht31_t4 === '-' ? null : parseFloat(dataArr[i].tstat_read_data.sht31_t4)
    if (ext4 === 0 && previousValidValues.ext4 !== null) {
      ext4 = previousValidValues.ext4
    }
    if (ext4 !== 0 && ext4 !== null) {
      previousValidValues.ext4 = ext4
    }
    relevantDataArr.push(ext4)

    result.push(relevantDataArr)
  }

  return result
}

function formatTemperatureChartData(dataArr) {
  let hasValues = false
  const chartData = dataArr.map((dataItem, i) => {
    let resultItem = []
    resultItem[0] = new Date(dataItem[0])
    resultItem[1] = parseFloat(dataItem[1])
    resultItem[2] = parseFloat(dataItem[2])
    resultItem[3] = parseFloat(dataItem[3])
    resultItem[4] = parseFloat(dataItem[4])
    resultItem[5] = parseFloat(dataItem[5])
    resultItem[6] = parseFloat(dataItem[6])
    resultItem[7] = parseFloat(dataItem[7])
    return resultItem
  })

  return chartData
}

export {
  buildTemperatureFromLiveStats,
  formatTemperatureChartData
}
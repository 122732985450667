

function dateFormatterInit(hasBreak) {
  return {
    valueFormatter: function (ms) {
      const dt = new Date(ms).toLocaleString()
      if (hasBreak) {
        return '<div class="legend-time">' + dt + '</div>'
      }
      return dt
    },
  }
}

export {
  dateFormatterInit
}

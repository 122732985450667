
import { makeLegendFormatter } from '../utils/legend-formatter.js'
import { dateFormatterInit } from '../utils/date-formatter.js'
import {
  MAX_VENT,
  MIN_VENT,
  LOW_PPM,
  HIGH_PPM,
  CO2_LABELS,
} from './_constants.js'
import {
   COLOR_GRAY_ONE, COLOR_GRAY_TWO, COLOR_GRAY_THREE, COLOR_GREEN, COLOR_RED, COLOR_BLUE_ONE, COLOR_BLUE_TWO 
} from '../_constants.js'

function getCo2ChartOptions(cnfg_dcv, datum) {
  const options = cnfg_dcv || {}
  const mode = options.mode || '2'
  console.log('cnfg_dcv', cnfg_dcv)
  console.log('co2 mode', mode)

  let labels = [].concat(CO2_LABELS)

  let ventilationAxis = {}
  // if 2 connect to economizer ( show low_ppm and high_ppm) on left
  if (mode === '2') {
    const indices = [CO2_LABELS.indexOf(MIN_VENT), CO2_LABELS.indexOf(MAX_VENT)]
    labels = labels.filter((_, i) => !indices.includes(i))
  } else if (mode === '1') {
    // if 1 ventilation (0-100) on right
    const indices = [CO2_LABELS.indexOf(LOW_PPM), CO2_LABELS.indexOf(HIGH_PPM)]
    labels = labels.filter((_, i) => !indices.includes(i))
    ventilationAxis = {
      'Min Vent': {
        axis: 'y2',
        // valueRange: [0, 100]
        // plotter: window.SmoothPlotter,
      },
      'Max Vent': {
        axis: 'y2',
        // valueRange: [0, 100]
        // plotter: window.SmoothPlotter,
      },
    }
  } else if (mode === '3') {
    // If 3 show set_point ) on left
    const indices = [CO2_LABELS.indexOf(LOW_PPM), CO2_LABELS.indexOf(HIGH_PPM)]
    labels = labels.filter((_, i) => !indices.includes(i))
  }

  // console.log('labels', labels)
  // console.log('datum', datum)

  const dygraphOptsCo2 = {
    height: 320,
    labels: labels,
    //  axisLabelFormatter: function(date, granularity, opts, dygraph) {
    //   // Format date as MM/DD/YY HH:MM
    //   const month = String(date.getMonth() + 1).padStart(2, '0');
    //   const day = String(date.getDate()).padStart(2, '0');
    //   const year = String(date.getFullYear()).slice(-2);
    //   const hours = String(date.getHours()).padStart(2, '0');
    //   const minutes = String(date.getMinutes()).padStart(2, '0');

    //   return `${month}/${day}/${year} ${hours}:${minutes}`;
    // },
    series: {
      // 'CO2 1': {
      //   axis: 'y1'
      // },
      Duty: {
        axis: 'y2',
        // valueRange: [0, 100]
        // plotter: window.SmoothPlotter,
      },
      ...ventilationAxis,
    },
    axes: {
      x: dateFormatterInit(),
      y2: {
        valueRange: [0, 110],
        axisLabelFormatter: function (y) {
          return y.toFixed(0)
        },
        axisLabelWidth: 50,
        independentTicks: true,
        ticker: function (min, max, pixels, opts, dygraph, vals) {
          const ticks = []
          for (let i = min; i <= max; i += 10) {
            ticks.push({ v: i, label: i.toFixed(0) })
          }
          return ticks
        },
      },
    },
    colors: ['#383838', '#5B5B5B', '#8E8E8E', '#C6C6C6', '#F21818', COLOR_GREEN, '#5998D1', '#1C579F'],
    //["#C6C6C6", "#8E8E8E", "#5B5B5B", "#383838"],
    labelsDiv: 'legendDivCo2',
    labelsSeparateLines: false,
    legend: 'always',
    legendFormatter: makeLegendFormatter(),
    animatedZooms: true,
  }
  // console.log('dygraphOptsCo2', dygraphOptsCo2)
  return {
    mode,
    co2_cnfg_dcv: cnfg_dcv,
    options: dygraphOptsCo2,
    data: datum,
  }
}

// https://dygraphs.com/tests/legend-formatter.html
// https://dygraphs.com/tests/value-axis-formatters.html

// const dygraphOptsCo2 = {
//   height: 320,
//   labels: [
//     'Datetime',
//     'CO2 1',
//     'CO2 2',
//     'CO2 3',
//     'CO2 4',
//     'Duty'
//   ],
//   series: {
//     // 'CO2 1': {
//     //   axis: 'y1'
//     // },
//     'Duty': {
//       axis: 'y2',
//       // valueRange: [0, 100]
//       // plotter: window.SmoothPlotter,
//     },
//   },
//   axes: {
//     y2: {
//       valueRange: [0, 110],
//       axisLabelFormatter: function(y) {
//         return y.toFixed(0)
//       },
//       axisLabelWidth: 50,
//       independentTicks: true,
//       ticker: function(min, max, pixels, opts, dygraph, vals) {
//         const ticks = []
//         for (let i = min; i <= max; i += 10) {
//           ticks.push({ v: i, label: i.toFixed(0) })
//         }
//         return ticks
//       }
//     }
//   },
//   colors: ['#383838', '#5B5B5B', '#8E8E8E', '#C6C6C6', '#F21818'],
//   //["#C6C6C6", "#8E8E8E", "#5B5B5B", "#383838"],
//   labelsDiv: 'legendDivCo2',
//   labelsSeparateLines: false,
//   legend: 'always',
//   animatedZooms: true,
// }

export {
  getCo2ChartOptions
}

import React from 'react'
import { arrayToCSV } from './array-to-csv'

const DownloadCSVButton = ({ data, pastData, macId, view, isLoading }) => {
  // console.log('view', view)
  const dataToUse = view === 'live' ? data : pastData || []

  if (isLoading) {
    return <button disabled>Loading...</button>
  }

  const downloadCSV = () => {
    if (!data) {
      return
    }
    if (!macId) {
      return
    }

    // Generate CSV string
    const csv = arrayToCSV(dataToUse)

    // Create a blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv' })

    // Create a link element
    const link = document.createElement('a')

    // Set the download attribute with a filename
    link.download = `device-data-${macId}.csv`

    // Create an object URL for the Blob
    link.href = URL.createObjectURL(blob)

    // Programmatically click the link to trigger the download
    link.click()

    // Clean up by revoking the Object URL
    URL.revokeObjectURL(link.href)
  }

  if (dataToUse.length === 0) {
    return null
  }

  return <button onClick={downloadCSV}>Download as CSV</button>
}

export default DownloadCSVButton

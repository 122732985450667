

/**
 * Flattens a nested object, converting nested keys into dot notation
 * @param {Object} obj - The nested object to flatten
 * @param {String} parent - Parent key (for recursive calls)
 * @param {Object} res - The result object (for recursive calls)
 * @returns {Object} - The flattened object
 */
function flattenObject(obj, parent = '', res = {}) {
  for (let key in obj) {
    const propName = parent ? `${parent}.${key}` : key
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      flattenObject(obj[key], propName, res)
    } else {
      res[propName] = obj[key]
    }
  }
  return res
}

/**
 * Converts an array of objects into CSV format with flattened key paths
 * @param {Array} data - The array of objects to convert
 * @returns {String} - The CSV string
 */
function arrayToCSV(data) {
  const flattenedData = data.map((item) => flattenObject(item))

  // Get unique headers from all the keys of the flattened objects
  let headers = [...new Set(flattenedData.flatMap((item) => Object.keys(item)))]

  // Move 'mac_address', 'device_id', and 'datetime' to the first three columns in that order
  headers = headers.filter((header) => !['mac_address', 'device_id', 'datetime'].includes(header))
  headers.unshift('datetime')
  headers.unshift('device_id')
  headers.unshift('mac_address')

  // Create CSV rows, starting with headers
  const csvRows = [headers.join(',')]

  // Add data rows
  flattenedData.forEach((item) => {
    const row = headers.map((header) => item[header] || '').join(',')
    csvRows.push(row)
  })

  return csvRows.join('\n')
}

export {
  arrayToCSV
}

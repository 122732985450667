function buildPressureFromLiveStats(dataArr) {
  let result = []
  let previousValidValues = {
    pressure1: null,
    pressure2: null,
    pDiff: null,
    fanDuty: null,
    econ: null
  }

  if (!dataArr || !dataArr.length) {
    return []
  }
  
  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Index 0 will hold datetime

    // Zone 1 Pressure
    let pressure1 = parseFloat(dataArr[i].tstat_read_data.live_zn_1?.pressure || 0)
    if (pressure1 === 0 && previousValidValues.pressure1 !== null) {
      pressure1 = previousValidValues.pressure1
    }
    if (pressure1 !== 0) {
      previousValidValues.pressure1 = pressure1
    }
    relevantDataArr.push(pressure1)

    // Zone 2 Pressure
    let pressure2 = parseFloat(dataArr[i].tstat_read_data.live_zn_2?.pressure || 0)
    if (pressure2 === 0 && previousValidValues.pressure2 !== null) {
      pressure2 = previousValidValues.pressure2
    }
    if (pressure2 !== 0) {
      previousValidValues.pressure2 = pressure2
    }
    relevantDataArr.push(pressure2)

    // Pressure Differential
    // let pDiff = parseFloat(dataArr[i].tstat_read_data.live_zn_1?.p_diff || 0)
    // if (pDiff === 0 && previousValidValues.pDiff !== null) {
    //   pDiff = previousValidValues.pDiff
    // }
    // if (pDiff !== 0) {
    //   previousValidValues.pDiff = pDiff
    // }
    // relevantDataArr.push(pDiff)

    // // Pressure Differential
    let tpDiff = parseFloat(dataArr[i].tstat_read_data.live_zn_1?.tp_diff || 0)
    if (tpDiff === 0 && previousValidValues.tpDiff !== null) {
      tpDiff = previousValidValues.tpDiff
    }
    if (tpDiff !== 0) {
      previousValidValues.tpDiff = tpDiff
    }
    relevantDataArr.push(tpDiff)

    // Fan Duty
    let fanDuty = parseFloat(dataArr[i].tstat_read_data.live_zn_1?.fan_duty || 0)
    if (fanDuty === 0 && previousValidValues.fanDuty !== null) {
      fanDuty = previousValidValues.fanDuty
    }
    if (fanDuty !== 0) {
      previousValidValues.fanDuty = fanDuty
    }
    relevantDataArr.push(fanDuty)

    // Economizer State
    let econ = isTrue(dataArr[i].tstat_read_data.live_zn_1?.eco_st) ? 1 : 0
    relevantDataArr.push(econ)

    result.push(relevantDataArr)
  }

  return result
}

function isTrue(value) {
  if (typeof value === 'undefined') {
    return false
  }
  return value === 'true' || typeof value === 'boolean' && value === true
}

function formatPressureData(dataArr) {
  const chartData = dataArr.map((dataItem) => {
    // console.log('dataItem', dataItem)
    return [
      new Date(dataItem[0]),           // Datetime
      dataItem[1],          // Zone 1 Pressure
      dataItem[2],          // Zone 2 Pressure
      dataItem[3],          // Pressure Differential
      dataItem[4],          // Fan Duty
      dataItem[5],          // Economizer State
    ]
  })

  return chartData
}

export {
  buildPressureFromLiveStats,
  formatPressureData
} 
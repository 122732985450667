import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Button } from 'react-bootstrap'
import TextArea from './TextArea'
import Showdown from 'showdown'

const markdown = new Showdown.Converter()
const BASE_URL = 'https://405l17ip5f.execute-api.us-west-1.amazonaws.com/prod'

function CommentsSection({ itemId }) {
  const [comments, setComments] = useState([])
  const [lastUpdate, setLastUpdate] = useState(null)

  if (!itemId) {
    return <div>loading notes</div>
  }
  // Update the comments list when a new comment is added
  function handleCommentAdded(newComment) {
    // setComments((prevComments) => [newComment, ...prevComments])
    setLastUpdate(new Date().toISOString())
  }

  return (
    <div>
      <h3>Notes</h3>
      <CommentList itemId={itemId} lastUpdate={lastUpdate} />
      <AddComment itemId={itemId} onCommentAdded={handleCommentAdded} />
    </div>
  )
}

function AddComment({ itemId, onCommentAdded }) {
  const [commentText, setCommentText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  // Handle form submission
  async function handleSubmit(event) {
    event.preventDefault()

    if (!commentText) {
      return alert('Please enter a comment')
    }

    setIsLoading(true)
    setError(null)

    try {
      const response = await fetch(`${BASE_URL}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ itemId, commentText }),
      })

      if (!response.ok) {
        throw new Error('Failed to add comment')
      }

      const data = await response.json()
      onCommentAdded(data) // Notify parent component that a new comment was added
      setCommentText('') // Clear the comment input
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} style={{ marginLeft: 20, marginTop: 20 }}>
      <TextArea
        style={{ display: 'block', width: '460px', minHeight: '180px', marginBottom: '8px' }}
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        placeholder='Add a new note to this device'
      />
      <Button type='submit' disabled={isLoading} className='btn btn-primary'>
        {isLoading ? 'Adding...' : 'Add Note to this device'}
      </Button>

      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
    </form>
  )
}

function CommentList({ itemId, lastUpdate }) {
  const [comments, setComments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  async function deleteComment(commentId) {
    // Show the browser's built-in confirm dialog
    const isConfirmed = window.confirm('Are you sure you want to delete this comment?')

    if (!isConfirmed) {
      return // User clicked "Cancel", exit the function
    }

    try {
      const response = await fetch(`${BASE_URL}/comments/${itemId}/${commentId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })

      if (!response.ok) {
        throw new Error('Failed to delete comment')
      }

      // Update the state to remove the deleted comment
      setComments(comments.filter((comment) => comment.commentId !== commentId))
    } catch (error) {
      console.error(error)
    }
  }

  // Fetch comments when the component mounts
  useEffect(() => {
    async function fetchComments() {
      setIsLoading(true)
      setError(null)

      try {
        const response = await fetch(`${BASE_URL}/comments/${itemId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })

        if (!response.ok) {
          throw new Error('Failed to fetch comments')
        }

        const data = await response.json()
        setComments(data)
      } catch (error) {
        setError(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchComments()
  }, [itemId, lastUpdate])

  if (isLoading) return <p>Loading comments...</p>
  if (error) return <p>Error loading comments: {error}</p>

  return (
    <ul style={{ paddingLeft: 20 }}>
      {comments.map((comment) => (
        <li key={comment.commentId} className='notes-item'>
          <div>{new Date(comment.commentId).toLocaleString()}</div>
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <div
              style={{ paddingTop: 8, marginRight: 20, maxWidth: 480, minHeight: 40 }}
              dangerouslySetInnerHTML={{ __html: markdown.makeHtml(comment.commentText) }}
            />
            <div className='notes-action-section'>
              <Button onClick={() => deleteComment(comment.commentId)} className='btn-sm'>
                Delete comment
              </Button>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default CommentsSection

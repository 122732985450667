
const DEFAULT_LINE_WIDTH = 1.2

const COLOR_GREEN = '#16B980' // old #159B00
const COLOR_RED = '#F21818'
const COLOR_BLUE_ONE = '#1C579F'
const COLOR_BLUE_TWO = '#5998D1'
const COLOR_GRAY_ONE = '#383838'
const COLOR_GRAY_TWO = '#8E8E8E'
const COLOR_GRAY_THREE = '#5B5B5B'

//     colors: ['#383838', '#5B5B5B', '#8E8E8E', '#C6C6C6', '#F21818', '#159B00', '#5998D1', '#1C579F'],


export {
  DEFAULT_LINE_WIDTH,
  COLOR_GREEN,
  COLOR_RED,
  COLOR_BLUE_ONE,
  COLOR_BLUE_TWO,
  COLOR_GRAY_ONE,
  COLOR_GRAY_TWO,
  COLOR_GRAY_THREE,
}
import {
  MAX_VENT,
  MIN_VENT,
  LOW_PPM,
  HIGH_PPM,
  CO2_LABELS,
} from './_constants.js'

function formatCo2Data(relevantDataArr, chartOptions) {
  const formattedCo2Data = relevantDataArr.map((dataItem, i) => {
    // console.log('dataItem', dataItem)
    let resultItem = []
    resultItem[0] = new Date(dataItem[0]) // Datetime string.
    //  State of relays 1 thru 7. See DataArrBuilder for meaning of these ints.
    // co2 1
    resultItem[1] = parseFloat(dataItem[1], 10)
    // co2 2
    resultItem[2] = parseFloat(dataItem[2], 10)
    // co2 3
    resultItem[3] = parseFloat(dataItem[3], 10)
    // co2 4
    resultItem[4] = parseFloat(dataItem[4], 10)
    // duty
    resultItem[5] = parseFloat(dataItem[5], 10)
    // low ppm
    resultItem[6] = parseFloat(dataItem[6], 10)
    // high ppm
    resultItem[7] = parseFloat(dataItem[7], 10)
    // set_point
    resultItem[8] = parseFloat(dataItem[8], 10)
    // min vent
    resultItem[9] = parseFloat(dataItem[9], 10)
    // max vent
    resultItem[10] = parseFloat(dataItem[10], 10)

    // Conditional display of data
    if (chartOptions.mode === '2') {
      const indices = [CO2_LABELS.indexOf(MIN_VENT), CO2_LABELS.indexOf(MAX_VENT)]
      return resultItem.filter((_, i) => !indices.includes(i))
    } else if (chartOptions.mode === '1') {
      // if 1 ventilation (0-100) on right
      const indices = [CO2_LABELS.indexOf(LOW_PPM), CO2_LABELS.indexOf(HIGH_PPM)]
      return resultItem.filter((_, i) => !indices.includes(i))
    } else if (chartOptions.mode === '3') {
      // If 3 show set_point ) on left
      const indices = [CO2_LABELS.indexOf(LOW_PPM), CO2_LABELS.indexOf(HIGH_PPM)]
      return resultItem.filter((_, i) => !indices.includes(i))
    }

    return resultItem
  })

  return formattedCo2Data
}

export { formatCo2Data }

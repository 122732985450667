
const HOURS = 12
const REFRESH_INTERVAL = HOURS * 60 * 60 * 1000

function initVersionChecker() {
  // Check immediately when app loads
  checkForNewVersion()
  
  // Set interval to check every 12 hours (in milliseconds)
  setInterval(checkForNewVersion, REFRESH_INTERVAL)
  
  // Alternatively, schedule next check based on last check time
  function scheduleNextCheck() {
    const lastCheckTime = localStorage.getItem('last_version_check')
    const now = Date.now()
    if (!lastCheckTime || now - parseInt(lastCheckTime) >= REFRESH_INTERVAL) {
      checkForNewVersion()
      localStorage.setItem('last_version_check', now.toString())
    }
    // Check again in an hour to see if 12 hours have passed
    setTimeout(scheduleNextCheck, REFRESH_INTERVAL)
  }
}

function checkForNewVersion() {
  const currentVersion = localStorage.getItem('app_version')
  console.log('currentVersion', currentVersion)
  const cacheBuster = new Date().getTime()

  fetch(`/build.json?cb=${cacheBuster}`)
    .then(response => response.json())
    .then(data => {
      console.log('build data', data)
      if (data && typeof data.version !== 'undefined' && data.version !== currentVersion) {
        localStorage.setItem('app_version', data.version)
        window.location.reload(true)
      }
    })
    .catch(error => {
      console.error('Error checking for new version:', error)
    })
}

export { initVersionChecker }
import { makeLegendFormatter } from '../utils/legend-formatter.js'
import { dateFormatterInit } from '../utils/date-formatter.js'


const econOnColor = 'rgba(246, 199, 136, .4)'

const pressureChartOptions = {
  height: 320,
  labels: [
    'Datetime',
    'Zone 1',
    'Zone 2',
    'Pressure Diff',
    'Fan Duty',
    'Economizer'
  ],
  series: {
    'Fan Duty': {
      axis: 'y2'
    },
    'Economizer': {
      axis: 'y2',
      valueFormatter: function(y) {
        if (y === 1) {
          return "<span style='color: #16B980; min-width: 20px; width: 20px; font-size: 10px; text-align: center;'>ON</span>";
        } else if (y === 0) {
          return "<span style='color: #878787; min-width: 20px; width: 20px; font-size: 10px; text-align: center;'>OFF</span>";
        }
        return y;
      }
    },
    'Pressure Diff': {
      axis: 'y2'
    }
  },
  axes: {
    x: dateFormatterInit(),
    y2: {
      valueRange: [0, 10],
      axisLabelWidth: 50,
      independentTicks: true
    }
  },
  colors: ['#159B00', '#1C579F', '#5998D1', '#F21818', '#383838'],
  labelsDiv: 'legendDivPressure',
  labelsSeparateLines: false,
  legend: 'always',
  legendFormatter: makeLegendFormatter((data) => {
    data.forEach(item => {
      if (item.label === 'Economizer') {
        if (item.y === 1) {
          item.yHTML = "<span style='color: #16B980; min-width: 20px; width: 20px; font-size: 10px; text-align: center;'>ON</span>";
        } else if (item.y === 0) {
          item.yHTML = "<span style='color: #878787; min-width: 20px; width: 20px; font-size: 10px; text-align: center;'>OFF</span>";
        }
      }
    });
    return data;
  }),
  animatedZooms: true,
  underlayCallback: function(canvas, area, g) {
    const rawCanvas = canvas.canvas || canvas;
    const ctx = rawCanvas.getContext('2d');
    
    const economizeIndex = g.getLabels().indexOf('Economizer');
    if (economizeIndex === -1) return;

    const points = g.xAxisRange();
    let lastStateChange = null;
    let isCurrentlyOn = null;

    // Check each point
    for (let i = 0; i < g.numRows(); i++) {
      const point = g.getValue(i, 0);
      if (point < points[0]) continue;
      if (point > points[1]) break;

      const value = g.getValue(i, economizeIndex);
      const isOn = value === 1;

      // Detect state changes
      if (isCurrentlyOn === null) {
        isCurrentlyOn = isOn;
        lastStateChange = point;
      } else if (isOn !== isCurrentlyOn) {
        // Draw the previous state if it was ON
        if (isCurrentlyOn) {
          const startX = g.toDomXCoord(lastStateChange);
          const endX = g.toDomXCoord(point);
          
          ctx.fillStyle = econOnColor; // tan color with transparency
          ctx.fillRect(startX, area.y, endX - startX, area.h);
        }
        
        // Update state
        isCurrentlyOn = isOn;
        lastStateChange = point;
      }
    }

    // Handle the final state to the end of the visible range
    if (lastStateChange !== null && isCurrentlyOn) {
      const startX = g.toDomXCoord(lastStateChange);
      const endX = g.toDomXCoord(points[1]);
      
      ctx.fillStyle = econOnColor; // tan color with transparency
      ctx.fillRect(startX, area.y, endX - startX, area.h);
    }
  }
}

export {
  pressureChartOptions
} 
import { makeLegendFormatter } from '../utils/legend-formatter.js'
import { dateFormatterInit } from '../utils/date-formatter.js'

const width = 20
const fontSize = 10
const dimColor = 1

/*
Relay Regex
/^W1:([01]), W2:([01]), Y1:([01]), Y2:([01]), O:([01]), B:([01]), G:([01]), H:([01]), D:([01])$/;

W1:([01]), // 1
W2:([01]), // 2
Y1:([01]), // 3
Y2:([01]), // 4
O:([01]), // 5
B:([01]), // 6
G:([01]), // 7
H:([01]), // 8
D:([01])$/; // 9

{
  relay1: matchAll[1],
  relay2: matchAll[2],
  relay3: matchAll[3],
  relay4: matchAll[4],
  relay5: matchAll[5],
  relay6: matchAll[6],
  relay7: matchAll[7],
  relay8: matchAll[8],
  relay9: matchAll[9],
}
*/

function getRelayChartOptions(legandDivId) {
  // const relayColors = ['#5B5B5B', '#383838', '#5998D1', '#1C579F', '#F21818', '#BC0808', '#159B00']

  const sucessColor = 'rgb(5, 150, 105)'
  const offColor = 'rgb(156, 163, 175)'

  const relayColors = [
    `rgb(139, 92, 246)`,
    `rgb(21, 94, 117)`,
    `rgb(6, 182, 212)`,
    `rgb(59, 130, 246)`,

    `rgb(251, 113, 133)`,
    // 'rgb(217, 70, 239)',
    `rgb(220, 38, 38)`,
    `rgb(34, 197, 94)`,
    // `rgb(71, 85, 105)`,
    //'rgb(16, 185, 129)',
  ]
  const dygraphOptsRelay = {
    height: 320,
    labels: ['Datetime', 'O', 'B', '1st Stage Cool', '2nd Stage Cool', '1st Stage Heat', '2nd Stage Heat', 'Fan'],
    colors: relayColors,
    labelsDiv: legandDivId,
    axes: {
      x: dateFormatterInit(true),
      y: {
        stepPlot: true,
        axisLabelWidth: 100,
        ticker: function (min, max, pixels, opts, dygraph, vals) {
          return [
            { v: 3, label: 'O' },
            { v: 7, label: 'B' },
            { v: 11, label: '1st Stage Cool' },
            { v: 15, label: '2nd Stage Cool' },
            { v: 19, label: '1st Stage Heat' },
            { v: 23, label: '2nd Stage Heat' },
            { v: 27, label: 'Fan' },
          ]
        },
        valueFormatter: function (y) {
          switch (y) {
            case 2:
              return offString()
              break
            case 4:
              return onString()
              break
            case 6:
              return offString()
              break
            case 8:
              return onString()
              break
            case 10:
              return offString()
              break
            case 12:
              return onString()
              break
            case 14:
              return offString()
              break
            case 16:
              return onString()
              break
            case 18:
              return offString()
              break
            case 20:
              return onString()
              break
            case 22:
              return offString()
              break
            case 24:
              return onString()
              break
            case 26:
              return offString()
              break
            case 28:
              return onString()
              break
          }
        },
      },
    },
    legend: 'always',
    legendFormatter: makeLegendFormatter(shiftRelayLegendSort),
    animatedZooms: true,
    underlayCallback: function (canvas, area, g) {
      const rawCanvas = canvas.canvas || canvas
      const ctx = rawCanvas.getContext('2d')

      const points = g.xAxisRange()

      let oldhighlightColors = [
        'rgba(91, 91, 91, 0.1)', // O - gray
        'rgba(56, 56, 56, 0.1)', // B - darker gray
        'rgba(89, 152, 209, 0.1)', // 1st Stage Cool - light blue
        'rgba(28, 87, 159, 0.1)', // 2nd Stage Cool - darker blue
        'rgba(242, 24, 24, 0.1)', // 1st Stage Heat - light red
        'rgba(188, 8, 8, 0.1)', // 2nd Stage Heat - darker red
        'rgba(21, 155, 0, 0.1)', // Fan - green
      ]

      let offlightColors = []

      // Define highlight colors for On/Off states
      const highlightColor = 'rgba(0, 255, 0, 0.1)' // green for On
      const offHighlightColor = 'rgba(167, 167, 167, 0.1)' // gray for Off

      // Convert RGB colors to RGBA with 0.1 opacity
      offlightColors = relayColors.map((color) => {
        // Extract RGB values from the color string
        const rgb = color.match(/\d+/g)
        return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.1)`
      })

      offlightColors = offlightColors.map((e, i) => getRgb(offColor, 0.2))

      const successColorDimmed = getRgb(sucessColor, 0.6)
      const offColorDimmed = getRgb(offColor, 0.4)

      oldhighlightColors = oldhighlightColors.map((e, i) => getRgb(relayColors[i], 0.75))
      // oldhighlightColors = oldhighlightColors.map((e, i) => successColorDimmed)
      // Map of y-values for each relay
      const relayYValues = {
        O: { on: 3, off: 3 },
        B: { on: 7, off: 7 },
        '1st Stage Cool': { on: 11, off: 11 },
        '2nd Stage Cool': { on: 15, off: 15 },
        '1st Stage Heat': { on: 19, off: 19 },
        '2nd Stage Heat': { on: 23, off: 23 },
        Fan: { on: 27, off: 27 },
      }

      // Remove the padding constant since we'll calculate exact positions
      // const padding = area.h / 29;

      // Draw highlights from back to front
      for (let i = g.numColumns() - 1; i >= 1; i--) {
        let lastStateChange = null
        let isCurrentlyOn = null

        const relayName = g.getLabels()[i]
        const yValues = relayYValues[relayName]

        // Check each point
        for (let j = 0; j < g.numRows(); j++) {
          const point = g.getValue(j, 0)
          if (point < points[0]) continue
          if (point > points[1]) break

          const value = g.getValue(j, i)
          const isOn = Math.abs(value % 4) === 0 && value > 2

          // Detect state changes
          if (isCurrentlyOn === null) {
            isCurrentlyOn = isOn
            lastStateChange = point
          } else if (isOn !== isCurrentlyOn) {
            // Draw the previous state
            const startX = g.toDomXCoord(lastStateChange)
            const endX = g.toDomXCoord(point)

            // Calculate exact y positions using the value pairs
            const yValue = isCurrentlyOn ? yValues.on : yValues.off
            const yPixel = g.toDomYCoord(yValue)
            const yPixelAbove = g.toDomYCoord(yValue + 1)
            const yPixelBelow = g.toDomYCoord(yValue - 1)
            const height = Math.abs(yPixelBelow - yPixelAbove)

            const fillColor = isCurrentlyOn ? oldhighlightColors[i - 1] : offlightColors[i - 1]
            const borderColor = isCurrentlyOn ? oldhighlightColors[i - 1] : offColorDimmed

            // Draw fill centered on the line
            ctx.fillStyle = fillColor
            ctx.fillRect(startX, yPixelAbove, endX - startX, height)

            // Draw borders
            ctx.beginPath()
            ctx.strokeStyle = borderColor
            ctx.lineWidth = 1
            ctx.moveTo(startX, yPixelAbove)
            ctx.lineTo(endX, yPixelAbove)

            // Draw bottom border only for "On" state
            if (isCurrentlyOn) {
              ctx.moveTo(startX, yPixelBelow)
              ctx.lineTo(endX, yPixelBelow)
            }
            ctx.stroke()

            // Update state
            isCurrentlyOn = isOn
            lastStateChange = point
          }
        }

        // Handle the final state to the end of the visible range
        if (lastStateChange !== null) {
          const startX = g.toDomXCoord(lastStateChange)
          const endX = g.toDomXCoord(points[1])

          // Calculate exact y positions
          const yValue = isCurrentlyOn ? yValues.on : yValues.off
          const yPixel = g.toDomYCoord(yValue)
          const yPixelAbove = g.toDomYCoord(yValue + 1)
          const yPixelBelow = g.toDomYCoord(yValue - 1)
          const height = Math.abs(yPixelBelow - yPixelAbove)

          const fillColor = isCurrentlyOn ? oldhighlightColors[i - 1] : offlightColors[i - 1]
          const borderColor = isCurrentlyOn ? oldhighlightColors[i - 1] : offColorDimmed

          // Draw fill
          ctx.fillStyle = fillColor
          ctx.fillRect(startX, yPixelAbove, endX - startX, height)

          // Draw borders
          ctx.beginPath()
          ctx.strokeStyle = borderColor
          ctx.lineWidth = 1
          ctx.moveTo(startX, yPixelAbove)
          ctx.lineTo(endX, yPixelAbove)

          // Draw bottom border only for "On" state
          if (isCurrentlyOn) {
            ctx.moveTo(startX, yPixelBelow)
            ctx.lineTo(endX, yPixelBelow)
          }
          ctx.stroke()
        }
      }
    },
  }

  return dygraphOptsRelay
}

function onString() {
  return `<span style='color: #16B980; min-width: ${width}px; width: ${width}px; font-size: ${fontSize}px; text-align: center;'>ON</span>`
}

function offString() {
  return `<span style='color: #878787; min-width: ${width}px; width: ${width}px; font-size: ${fontSize}px; text-align: center;'>OFF</span>`
}

function getRgb(color, opacity) {
  const rgb = color.match(/\d+/g)
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`
}

function shiftRelayLegendSort(array) {
  return array.sort((a, b) => {
    const labelsToMove = ['B', 'O']
    const aIsSpecial = labelsToMove.includes(a.label)
    const bIsSpecial = labelsToMove.includes(b.label)

    if (aIsSpecial && !bIsSpecial) return 1
    if (!aIsSpecial && bIsSpecial) return -1
    return 0
  })
}

export {
  getRelayChartOptions
}

import constants from '../../../../../config/constants.js'

function buildCo2DataFromLiveStats(dataArr, config) {
  let result = []

  // console.log('buildCo2DataFromLiveStats', dataArr)

  if (!dataArr || !dataArr.length) {
    // Not suitable for graphing. Return empty.
    return []
  }

  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Datetime.

    //SCD CO2 SENSOR
    //console.log("dataArr[i].tstat_read_data: " + JSON.stringify(dataArr[i].tstat_read_data));
    const _SCD = constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR
    const prop = constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[_SCD]
    
    if (
      dataArr[i].tstat_read_data.hasOwnProperty('live_zn_1') &&
      typeof dataArr[i].tstat_read_data['live_zn_1'] === 'object'
    ) {
      var livedataCo2SensorObj = dataArr[i].tstat_read_data['live_zn_1']
      //console.log("livedataCo2SensorObj: ", livedataCo2SensorObj);

      if (livedataCo2SensorObj.hasOwnProperty('co2')) {
        //console.log('about to save co2: ', livedataCo2SensorObj[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_CO2]]);
        relevantDataArr.push(
          livedataCo2SensorObj['co2'],
        )
        //req.livedata.tstat_read_data[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES][] = req.body[constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR][constants.THERMOSTAT_COMMAND_KEYS_OBJ.CO2_SENSOR_TEMPERATURE];
      } else relevantDataArr.push(null)
    } else {
      relevantDataArr.push(null)
    }
    
    //relevantDataArr.push(dataArr[i].tstat_read_data.co2_1 === "-" ? null : dataArr[i].tstat_read_data.co2_2);
    if (dataArr[i].tstat_read_data && dataArr[i].tstat_read_data.live_zn_2 && dataArr[i].tstat_read_data.live_zn_2.co2) {
      relevantDataArr.push(dataArr[i].tstat_read_data.live_zn_2.co2)
    } else {
      relevantDataArr.push(null)
    }

    //relevantDataArr.push(dataArr[i].tstat_read_data.co2_1 === "-" ? null : dataArr[i].tstat_read_data.co2_3);
    if (dataArr[i].tstat_read_data && dataArr[i].tstat_read_data.live_zn_3 && dataArr[i].tstat_read_data.live_zn_3.co2) {
      relevantDataArr.push(dataArr[i].tstat_read_data.live_zn_3.co2)
    } else {
      relevantDataArr.push(null)
    }
    //relevantDataArr.push(dataArr[i].tstat_read_data.co2_1 === "-" ? null : dataArr[i].tstat_read_data.co2_4);
    
    if (dataArr[i].tstat_read_data && dataArr[i].tstat_read_data.live_zn_4 && dataArr[i].tstat_read_data.live_zn_4.co2) {
      relevantDataArr.push(dataArr[i].tstat_read_data.live_zn_4.co2)
    } else {
      relevantDataArr.push(null)
    }

    // console.log('constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY', constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY)

    // DUTY
    if (
      dataArr[i].tstat_read_data.hasOwnProperty(
        constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY],
      ) &&
      typeof dataArr[i].tstat_read_data[
        constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY]
      ] === 'string'
    ) {
      relevantDataArr.push(
        dataArr[i].tstat_read_data[
          constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.DUTY]
        ],
      )
    } else {
      relevantDataArr.push(null)
    }

    const config = dataArr[i].cnfg_dcv
    if (config) {
      relevantDataArr.push(config.set_point || null)
      relevantDataArr.push(config.low_ppm || null)
      relevantDataArr.push(config.high_ppm || null)
      relevantDataArr.push(config.min_vent || null)
      relevantDataArr.push(config.max_vent || null)
    }

    result.push(relevantDataArr)
  }

  return result
}

export {
  buildCo2DataFromLiveStats
}



//  DataArr is the unfiltered list of live data across a time period.
//    From it, we read relay states.
//  DeviceRelayConfig tells us the order of the relays. There are seven.
//    They are: Y (Cool Stage 1), Y2 (Cool Stage 2), W (Heat Stage 1), W2 (Heat Stage 2), G (Fan), O, B.
//    They could be in any order. We need to put them into a predictable
//    order so we can graph them. Except O and B, which we ignore.
function buildRelayDataFromLiveStats(dataArr, deviceRelayConfig) {
  let result = []
  if (!dataArr || !dataArr.length) {
    // Not suitable for graphing. Return empty.
    return []
  }

  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Index 0 will hold datetime!
    //  Indices 1 through 7 will hold relays: Y, Y2, W, W2, G.

    //W1:0, W2:0, Y1:0, Y2:0, O:0, B:0, G:1, H:0, D:0
    //  Y
    //let key = findKeyOfRelayNameValue(deviceRelayConfig, "Y");
    let key = 'relay5'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(2)
    } else {
      relevantDataArr.push(4)
    }

    //  Y2
    //key = findKeyOfRelayNameValue(deviceRelayConfig, "Y2");
    key = 'relay6'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(6)
    } else {
      relevantDataArr.push(8)
    }

    //  W
    //key = findKeyOfRelayNameValue(deviceRelayConfig, "W");
    key = 'relay3' 
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(10)
    } else {
      relevantDataArr.push(12)
    }

    //  W2
    //key = findKeyOfRelayNameValue(deviceRelayConfig, "W2");
    key = 'relay4'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(14)
    } else {
      relevantDataArr.push(16)
    }

    //  G
    //key = findKeyOfRelayNameValue(deviceRelayConfig, "G");
    key = 'relay1' // 'relay7'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(18)
    } else {
      relevantDataArr.push(20)
    }


    key = 'relay2' // 'relay5'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(22)
    } else {
      relevantDataArr.push(24)
    }


    key = 'relay7'
    if (dataArr[i].tstat_read_data.relay_state[key] === '0') {
      relevantDataArr.push(26)
    } else {
      relevantDataArr.push(28)
    }

    result.push(relevantDataArr)
  }

  console.log('relay result', result)

  return result
}

function formatRelayChartData(dataArr) {
  const chartData = dataArr.map((dataItem, i) => {
    let resultItem = []
    resultItem[0] = new Date(dataItem[0]) // Datetime string.
    //  State of relays 1 thru 7. See relays format.js for meaning of these ints.
    resultItem[1] = parseInt(dataItem[1], 10)
    resultItem[2] = parseInt(dataItem[2], 10)
    resultItem[3] = parseInt(dataItem[3], 10)
    resultItem[4] = parseInt(dataItem[4], 10)
    resultItem[5] = parseInt(dataItem[5], 10)
    resultItem[6] = parseInt(dataItem[6], 10)
    resultItem[7] = parseInt(dataItem[7], 10)

    return resultItem
  }) 
  return chartData
}

//  DeviceRelayConfig: the object that tells us the order of the relays.
//    It has keys of the format rl_1 through rl_7.
//  NameValue: The relay we are searching for. It is either
//    Y, Y2, W, W2, or G.
//  The output: a key that corresponds with the format used
//    for relay states in liveData. Format is relay1 through relay7.
function findKeyOfRelayNameValue(deviceRelayConfig, nameValue) {
  // key is just a string that represents a relay i.e. "Y"
  if (deviceRelayConfig['rl_1'] === nameValue) {
    return 'relay1'
  } else if (deviceRelayConfig['rl_2'] === nameValue) {
    return 'relay2'
  } else if (deviceRelayConfig['rl_3'] === nameValue) {
    return 'relay3'
  } else if (deviceRelayConfig['rl_4'] === nameValue) {
    return 'relay4'
  } else if (deviceRelayConfig['rl_5'] === nameValue) {
    return 'relay5'
  } else if (deviceRelayConfig['rl_6'] === nameValue) {
    return 'relay6'
  } else if (deviceRelayConfig['rl_7'] === nameValue) {
    return 'relay7'
  }
}

export {
  buildRelayDataFromLiveStats,
  formatRelayChartData
}
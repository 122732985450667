

const STREAMING_URL = 'https://pjr4pgoaahk5yc2c5agofuwina0rblfq.lambda-url.us-west-2.on.aws'
const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI5MjI0Nzc5MC0xODkyLTExZWYtOGUyOS0wZjFjYzc5ZmE2MjgiLCJ1c2VybmFtZSI6InRlcGlzdG9jaGluaUB1Y2RhdmlzLmVkdSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNzMyMjk2ODA2LCJleHAiOjE3MzI5MDE2MDZ9.GATOGj1X33-Yx3VbvBOkxLG6cG-cs6KRih0KRaiHiec'
const siteId = '6946f600-49d3-11ef-a544-cbbe36bf79b7'
const deviceId = '9f4d0fd0-de24-11ed-a466-9df5e7cdb79d'
/**
 * Fetch thermostat data from the streaming endpoint
 * @param {string} id - Thermostat ID
 * @param {string} start - Start timestamp
 * @param {string} end - End timestamp
 * @returns {Promise<object>} Parsed thermostat data
 */
async function getBulkData({ siteId, deviceId, start, end, onLoading, onError, onComplete }) {
  const userToken = localStorage.getItem('token')
  if (!userToken) {
    window.location.reload()
  }

  if (onLoading) {
    onLoading()
  }
  console.log(`Get ${deviceId} data from ${start} to ${end}`)
  const response = await fetch(STREAMING_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
    body: JSON.stringify({
      siteId,
      deviceId,
      start,
      end,
    }),
  })

  if (!response.ok) {
    const errorText = await response.json()
    console.error('Error response:', errorText)
    if (onError) {
      onError(errorText)
    }
    throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`)
  }

  const reader = response.body.getReader()
  const decoder = new TextDecoder()

  let count = 0
  let done = false
  let allData = ''

  while (!done) {
    const { value, done: readerDone } = await reader.read()
    done = readerDone

    // Decode the stream chunk and append to data
    if (value) {
      const chunk = decoder.decode(value, { stream: true })
      allData += chunk // Append chunk to the full data
      //console.log(`Received chunk ${count++}:`, chunk)
    }
  }

  // Log out size of data
  // console.log('Data:', allData)
  console.log('Data size:', allData.length)

  const parsedData = JSON.parse(allData)
  console.log('Complete data:', parsedData)

  if (onComplete) {
    onComplete(parsedData)
  }

  return {
    data: parsedData,
  }
}

export {
  getBulkData
}

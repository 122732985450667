import { makeLegendFormatter } from '../utils/legend-formatter.js'
import { dateFormatterInit } from '../utils/date-formatter.js'
import { COLOR_GREEN, COLOR_RED, COLOR_BLUE_ONE, COLOR_BLUE_TWO, COLOR_GRAY_ONE, COLOR_GRAY_TWO, COLOR_GRAY_THREE } from '../_constants.js'

const temperatureChartOptions = {
  height: 340,
  labels: [
    'Datetime',
    'Zone Temp',
    'Heat Setpoint',
    'Cool Setpoint',
    'External 1',
    'External 2',
    'External 3',
    'External 4',
  ],
  // colors: ['#159B00', '#F21818', '#1C579F', '#C6C6C6', '#8E8E8E', '#5B5B5B', '#383838'],
  colors: [
    COLOR_GREEN, 
    COLOR_RED, 
    COLOR_BLUE_ONE, 
    COLOR_BLUE_TWO, 
    COLOR_GRAY_ONE, 
    COLOR_GRAY_TWO, 
    COLOR_GRAY_THREE
  ],
  labelsDiv: 'legendDivTemp',
  axes: {
    x: dateFormatterInit(),
  },
  labelsSeparateLines: false,
  legend: 'always',
  legendFormatter: makeLegendFormatter(),
  animatedZooms: true,
}

export {
  temperatureChartOptions
}


function buildVolatileGasFromLiveStats(dataArr, config) {
  let result = []

  if (!dataArr || !dataArr.length) {
    // Not suitable for graphing. Return empty.
    return []
  }

  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Datetime.
    if (
      dataArr[i].tstat_read_data && 
      dataArr[i].tstat_read_data.hasOwnProperty('live_zn_1') &&
      typeof dataArr[i].tstat_read_data['live_zn_1'] === 'object'
    ) {
      var liveData = dataArr[i].tstat_read_data['live_zn_1']

      if (liveData.voc_index) {
        relevantDataArr.push(liveData.voc_index)
      } else {
        relevantDataArr.push(null)
      }
    }
    result.push(relevantDataArr)
  }
  return result
}

function formatVolatileGasChartData(dataArr) {
  const chartData = dataArr.map((dataItem, i) => {
    let resultItem = []
    resultItem[0] = new Date(dataItem[0])
    resultItem[1] = parseFloat(dataItem[1])
    return resultItem
  })
  return chartData
}

export {
  buildVolatileGasFromLiveStats,
  formatVolatileGasChartData
}
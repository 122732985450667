

function buildFineParticulatesFromLiveStats(dataArr) {
  let result = []

  if (!dataArr || !dataArr.length) {
    // Not suitable for graphing. Return empty.
    return []
  }

  for (let i = 0; i < dataArr.length; i++) {
    let relevantDataArr = []
    relevantDataArr.push(dataArr[i].datetime) // Datetime.
    if (
      dataArr[i].tstat_read_data && 
      dataArr[i].tstat_read_data.hasOwnProperty('live_zn_1') &&
      typeof dataArr[i].tstat_read_data['live_zn_1'] === 'object'
    ) {
      var liveData = dataArr[i].tstat_read_data['live_zn_1']

      if (liveData.sps && liveData.sps) {
        relevantDataArr.push(liveData.sps.mc_2p5)
        relevantDataArr.push(liveData.sps.nc_2p5)
      } else {
        relevantDataArr.push(null)
        relevantDataArr.push(null)
      }
    }
    result.push(relevantDataArr)
  }
  return result
}

export {
  buildFineParticulatesFromLiveStats
}
import { makeLegendFormatter } from '../utils/legend-formatter.js'

const fineParticulatesChartOptions = {
  height: 320,
  labels: ['Datetime', 'Mass Concentration', 'Number Concentration'],
  colors: ['#159B00', '#F21818', '#1C579F', '#C6C6C6', '#8E8E8E', '#5B5B5B', '#383838'],
  labelsDiv: 'legendDivSps',
  labelsSeparateLines: false,
  legend: 'always',
  legendFormatter: makeLegendFormatter(),
  animatedZooms: true,
}

export {
  fineParticulatesChartOptions
}
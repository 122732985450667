import React, { Component, useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { XPanel, PageTitle } from '../../components'
import CommentsSection from './Comments'
import Moment from 'moment'
//import Dygraph from 'dygraphs';
import Dygraph from '../../../dygraphs'
import Spinner from '../../indicators/spinner.js'
import PastDatePick from './pastdatepick.js'
import DownloadCSVButton from './DownloadButton'
import { getRelayChartOptions } from './dygraph/relay-chart/options'
import { getCo2ChartOptions } from './dygraph/co2-chart/options'
import { temperatureChartOptions } from './dygraph/temp-chart/options'
import { volatileGasChartOptions } from './dygraph/volatile-gas-chart/options'
import { fineParticulatesChartOptions } from './dygraph/fine-particulates-chart/options'
import { formatCo2Data } from './dygraph/co2-chart'
import { buildTemperatureFromLiveStats, formatTemperatureChartData } from './dygraph/temp-chart/format'
import { buildCo2DataFromLiveStats } from './dygraph/co2-chart/format'
import { buildRelayDataFromLiveStats, formatRelayChartData } from './dygraph/relay-chart/format'
import { buildFineParticulatesFromLiveStats } from './dygraph/fine-particulates-chart/format'
import { buildVolatileGasFromLiveStats, formatVolatileGasChartData } from './dygraph/volatile-gas-chart/format'
import { getBulkData } from './util/fetch-streaming-data.js'
import { normalizeDateTimes } from './dygraph/utils/normalize-data'
import './dataanalysis.css'

import api from '../../util/api.js'

const { getDevice } = api

// duty on all charts
const modeMap = {
  0: 'OFF',
  1: 'Ventilation', // Display max and min vent on right side of chart 0-100
  2: 'Economizer', // PPM high and ppm low on left side of chart 0-2500
  3: 'Actuator', // min vent and max vent on right side of chart 0-100 and co2 set point on left side of chart 0-2500 cfng_dcv."set_point"
}

/* old mode map
const modeMap = {
  '1': 'Ventilation',
  '2': 'Economzier',
  '3': 'Actuator',
}
*/

function checkIfNonThermastat(deviceInfo, allData) {
  console.log('deviceInfo', deviceInfo)
  const lastItem = (allData && allData.length > 0) ? allData[allData.length - 1] : null

  if (lastItem) {
    console.log('deviceInfo lastItem', lastItem)
  }

  return (
    (deviceInfo && deviceInfo.dt && deviceInfo.dt === 'AQ Indoor') ||
    (deviceInfo && deviceInfo.deviceType && deviceInfo.deviceType === 3)
  )
}

//  Input: Some array of data & some new array of matching data.
//  Output: One concatenated array, with the appropriate
//    number of items removed from the front.
//    The number of items removed from the front should match the number
//    added to the end.
function combineData(arrOld, arrNew) {
  console.log('combineData arrOld.length', arrOld.length)
  console.log('combineData arrNew.length', arrNew.length)
  let result = arrOld.concat(arrNew)
  if (arrOld.length > arrNew.length) {
    // result = result.slice(arrNew.length, result.length)
  }
  console.log('combineData result.length', result.length)
  return result
}

let configCache

class DataAnalysis extends React.Component {
  constructor() {
    super()

    this.graphTempDiv = null
    this.graphRelayDiv = null
    this.graphCo2Div = null
    // Non thermastat charts
    this.graphVolatileGasDiv = null
    this.graphFineParticulatesDiv = null

    this.setGraphTempDiv = (element) => {
      this.graphTempDiv = element
    }

    this.setGraphRelayDiv = (element) => {
      this.graphRelayDiv = element
    }

    this.setGraphRelayMasterDiv = (element) => {
      this.graphRelayMasterDiv = element
    }

    this.setGraphCo2Div = (element) => {
      this.graphCo2Div = element
    }

    // Non thermastat charts
    this.setGraphVoCDiv = (element) => {
      this.graphVolatileGasDiv = element
    }

    this.setGraphSpsDiv = (element) => {
      this.graphFineParticulatesDiv = element
    }

    const parentDeviceId = getParentId()
    console.log('parentDeviceId', parentDeviceId)

    this.state = {
      allData: [],
      // get parent mac from url params /analysis?parent=34-6F-24-5F-22-2D
      parentDeviceId: parentDeviceId,
      /* Temperature */
      liveTempData: [],
      pastTempData: [],
      /* CO2 */
      liveCo2Data: [],
      pastCo2Data: [],
      /* Relay */
      liveRelayData: [],
      pastRelayData: [],
      /* Relay Master */
      liveMasterRelayData: [],
      pastMasterRelayData: [],
      /* Volatile Gas */
      liveVolatileGasData: [],
      pastVolatileGasData: [],
      /* Fine Particulates */
      liveFineParticulatesData: [],
      pastFineParticulatesData: [],

      apiCalling: false,
      deviceRelayConfig: {},
      deviceName: null,
      deviceMac: null,
      viewMode: 'live',
      deviceEt: 60,
      //Graphs we maintain in state
      temperatureGraph: undefined,
      relayGraph: undefined,
      co2Graph: undefined,
      vocGraph: undefined,
      spsGraph: undefined,
      co2_cnfg_dcv: {},
    }
  }

  componentDidMount() {
    //  Initial time parameters are declared and stored in state.
    let endTime = Moment()
    let startTime = Moment(endTime).subtract(12, 'hours')

    getDevice(this.props.siteID, this.props.deviceID).then((res) => {
      //  Get Live Data and draw graphs.
      //  Also, set up the interval by which we will continue to get Live Data and draw graphs.

      console.log('~~~~~~~~~~~ configuration is: ', res.data.device.configuration)
      if (!res.data.device.configuration.hasOwnProperty('et')) {
        console.log('configuration is missing et, setting to 60')
        res.data.device.configuration.et = 60
      }

      this.setState(
        {
          endTime: endTime,
          startTime: startTime,
          //deviceRelayConfig: res.data.device.configuration[constants.THERMOSTAT_COMMAND_KEYS_HUMAN_NAMES[constants.THERMOSTAT_COMMAND_KEYS_OBJ.RELAY_CONFIG]],
          deviceName: res.data.device.configuration.lbl || res.data.device.name,
          deviceMac: res.data.device.mac_address,
          deviceEt: res.data.device.configuration.et,
        },
        () => {
          this.getLiveDataAndDrawGraphs()
          let regRefreshDataAndGraphs = setInterval(this.getLiveDataAndDrawGraphs, this.state.deviceEt * 1000)
          this.setState({
            regRefreshDataAndGraphs: regRefreshDataAndGraphs,
          })
        },
      )
    })
  }

  componentWillUnmount() {
    if (this.state.regRefreshDataAndGraphs) {
      clearInterval(this.state.regRefreshDataAndGraphs)
    }
  }

  //  If Live mode is selected, populates graphs with live data and sets up
  //    regular check-ins.
  //  If Past mode is selected, clears regular check-ins.
  handleChangeViewMode = (newMode) => {
    this.setState(
      {
        viewMode: newMode,
      },
      () => {
        if (this.state.viewMode === 'live') {
          this.drawAllGraphs({
            tempData: this.state.liveTempData,
            relayData: this.state.liveRelayData,
            relayMasterData: this.state.liveMasterRelayData,
            co2Data: this.state.liveCo2Data,
            vocData: this.state.liveVolatileGasData,
            spsData: this.state.liveFineParticulatesData,
            co2ChartOptions: {
              mode: this.state.co2Mode,
            },
            shouldRedraw: true,
            caller: 'handleChangeViewMode live',
          })
          let regRefreshDataAndGraphs = setInterval(this.getLiveDataAndDrawGraphs, this.state.deviceEt * 1000)
          this.setState({
            regRefreshDataAndGraphs: regRefreshDataAndGraphs,
          })
        } else if (this.state.viewMode === 'past') {
          this.drawAllGraphs({
            tempData: this.state.pastTempData,
            relayData: this.state.pastRelayData,
            relayMasterData: this.state.pastMasterRelayData,
            co2Data: this.state.pastCo2Data,
            vocData: this.state.pastVolatileGasData,
            spsData: this.state.pastFineParticulatesData,
            co2ChartOptions: {
              mode: this.state.co2Mode,
            },
            shouldRedraw: true,
            caller: 'handleChangeViewMode past',
          })
          clearInterval(this.state.regRefreshDataAndGraphs)
        }
      },
    )
  }

  getLiveDataAndDrawGraphs = () => {
    const isNonThermastat = checkIfNonThermastat(this.props.deviceInfo)
    this.setState({ apiCalling: true })

    /* Use parent mac ID to get parent data */
    const parentData = !this.state.parentDeviceId
      ? new Promise((resolve) => resolve({ data: [] }))
      : getBulkData({
          siteId: this.props.siteID,
          deviceId: this.state.parentDeviceId,
          start: this.state.startTime,
          end: this.state.endTime,
        })

    /* Use device mac ID to get device data */
    const deviceData = getBulkData({
      siteId: this.props.siteID,
      deviceId: this.props.deviceID,
      start: this.state.startTime,
      end: this.state.endTime,
    })

    Promise.all([deviceData, parentData])
      .then((results) => {
        console.log('Both promises resolved!')
        const res = normalizeDateTimes(results[0])
        const parentRes = normalizeDateTimes(results[1])
        console.log('Result of promise1:', res)
        console.log('Result of promise2:', parentRes)
        console.log('getBulkData bulk', res.data)

        //res.data = this.removeAnomalousData(res.data);
        const co2Data = combineData(this.state.liveCo2Data, buildCo2DataFromLiveStats(res.data))
        let lastItem = res.data[res.data.length - 1]
        if (!lastItem && configCache) {
          lastItem = configCache
        }
        if (lastItem) {
          configCache = lastItem
        }
        const lastItemCnf = (lastItem || {}).cnfg_dcv
        console.log('co2Data', co2Data)
        if (co2Data.length > 0) {
          // log out the last item in the co2Data array
          console.log('co2Data date', co2Data[co2Data.length - 1][0])
        }
        console.log('lastItem', lastItem)
        if (lastItem) {
          console.log('lastItem.cnfg_dcv', lastItemCnf)
        }

        let vocDataLive = []
        let spsDataLive = []
        if (this.props.deviceInfo && this.props.deviceInfo.dt && isNonThermastat) {
          vocDataLive = combineData(this.state.liveVolatileGasData, buildVolatileGasFromLiveStats(res.data))
          spsDataLive = combineData(this.state.liveFineParticulatesData, buildFineParticulatesFromLiveStats(res.data))
        }

        const liveTempData = combineData(
          this.state.liveTempData,
          buildTemperatureFromLiveStats(res.data),
        )
        const liveRelayData = combineData(
          this.state.liveRelayData,
          buildRelayDataFromLiveStats(res.data, this.state.deviceRelayConfig),
        )
        const liveMasterRelayData = combineData(
          this.state.liveMasterRelayData,
          buildRelayDataFromLiveStats(parentRes.data, this.state.deviceRelayConfig),
        )
        const allData = this.state.allData.concat(res.data)

        console.log('allData.length', allData.length)

        this.setState(
          {
            allData: allData,
            liveTempData: liveTempData,
            liveRelayData: liveRelayData,
            liveMasterRelayData: liveMasterRelayData,
            liveCo2Data: co2Data,
            // non thermastat chart data
            liveVolatileGasData: vocDataLive,
            liveFineParticulatesData: spsDataLive,
            // Time parameters are updated for next time.
            startTime: this.state.endTime,
          },
          () => {
            const chartDetails = getCo2ChartOptions(lastItemCnf, this.state.liveCo2Data)
            console.log('chartDetails', chartDetails)
            this.setState({
              endTime: Moment(),
              co2Mode: chartDetails.mode,
              co2_cnfg_dcv: chartDetails.co2_cnfg_dcv,
            })
            console.log(
              '> drawAllGraphs this.state.liveRelayData.length',
              this.state.liveRelayData.length,
            )
            console.log('> drawAllGraphs liveRelayData.length', liveRelayData.length)

            this.drawAllGraphs({
              tempData: this.state.liveTempData,
              relayData: this.state.liveRelayData,
              relayMasterData: this.state.liveMasterRelayData,
              co2Data: this.state.liveCo2Data,
              vocData: this.state.liveVolatileGasData,
              spsData: this.state.liveFineParticulatesData,
              co2ChartOptions: chartDetails,
              caller: 'getLiveDataAndDrawGraphs',
            })
            this.setState({ apiCalling: false })
          },
        )
        return results // Return the results of both promises
      })
      .catch((error) => {
        console.error('One or both promises rejected:', error)
        throw error // Propagate the error
      })
  }

  getPastDataAndDrawGraphs = (startTime, endTime) => {
    console.log('getPastDataAndDrawGraphs', startTime, endTime)
    const isNonThermastat = checkIfNonThermastat(this.props.deviceInfo)
    this.setState({ apiCalling: true })

    /* Use parent mac ID to get parent data */
    const parentData = !this.state.parentDeviceId
      ? new Promise((resolve) => resolve({ data: [] }))
      : getBulkData({
          siteId: this.props.siteID,
          deviceId: this.state.parentDeviceId,
          start: startTime,
          end: endTime,
        })

    /* Use device mac ID to get device data */
    const deviceData = getBulkData({
      siteId: this.props.siteID,
      deviceId: this.props.deviceID,
      start: startTime,
      end: endTime,
    })

    Promise.all([deviceData, parentData]).then((results) => {
      console.log('Both promises resolved!')
      const res = normalizeDateTimes(results[0])
      const parentRes = normalizeDateTimes(results[1])
      console.log('Past data', res)
      res.data = this.removeAnomalousData(res.data)

      let vocDataPast = []
      let spsDataPast = []
      if (this.props.deviceInfo && this.props.deviceInfo.dt && isNonThermastat) {
        vocDataPast = buildVolatileGasFromLiveStats(res.data)
        spsDataPast = buildFineParticulatesFromLiveStats(res.data)
      }

      const tempData = buildTemperatureFromLiveStats(res.data)
      const relayData = buildRelayDataFromLiveStats(res.data, this.state.deviceRelayConfig)
      const co2Data = buildCo2DataFromLiveStats(res.data)
      const pastMasterRelayData = buildRelayDataFromLiveStats(parentRes.data, this.state.deviceRelayConfig)
      //*
      console.log('tempData past', tempData)
      console.log('relayData past', relayData)
      console.log('co2Data past', co2Data)
      console.log('vocDataPast past', vocDataPast)
      console.log('spsDataPast past', spsDataPast)
      /** */

      this.setState(
        {
          allPastData: res.data,
          pastTempData: tempData,
          pastRelayData: relayData,
          pastMasterRelayData: pastMasterRelayData,
          pastCo2Data: co2Data,
          pastVolatileGasData: vocDataPast,
          pastFineParticulatesData: spsDataPast,
        },
        () => {
          this.drawAllGraphs({
            tempData: this.state.pastTempData,
            relayData: this.state.pastRelayData,
            relayMasterData: this.state.pastMasterRelayData,
            co2Data: this.state.pastCo2Data,
            vocData: this.state.pastVolatileGasData,
            spsData: this.state.pastFineParticulatesData,
            shouldRedraw: true,
            co2ChartOptions: {
              mode: this.state.co2Mode,
            },
            caller: 'getPastDataAndDrawGraphs',
          })
          this.setState({ apiCalling: false })
        },
      )
    })
  }

  removeAnomalousData = (dataArr) => {
    console.log('dataanalysis removeAnomalousData() dataArr: ', dataArr)
    
    //console.log();
    let newDataArr = []

    // for (let i = 0; i < dataArr.length; i++) {
    //   dataArr[i].tstat_read_data.zone_temp

    //   //First datapoint
    //   if(i == 0){
    //     if(Math.abs(dataArr[i].tstat_read_data.zone_temp - dataArr[i+1].tstat_read_data.zone_temp) >= 35){
    //       //do nothing
    //       console.log("skipping anomoly data dataanalysis");
    //       continue;
    //     }
    //   }
    //   //Inbetween datapoints
    //   else if(i !== dataArr.length-2){
    //     //zone temp jumped by over 35 degrees in one ET period, must be an anomoly
    //     console.log("inbetween datapoints dataanalysis, i-1 zone_temp: ", dataArr[i-1].tstat_read_data.zone_temp, " i zone_temp: ", dataArr[i].tstat_read_data.zone_temp);
    //     if(Math.abs(dataArr[i-1].tstat_read_data.zone_temp - dataArr[i].tstat_read_data.zone_temp) >= 35){
    //       //do nothing
    //       console.log("skipping anomoly data dataanalysis");
    //       continue;
    //     }
    //     else if(Math.abs(dataArr[i].tstat_read_data.zone_temp - dataArr[i+1].tstat_read_data.zone_temp) >= 35){
    //       //do nothing
    //       console.log("skipping anomoly data dataanalysis");
    //       continue;
    //     }
    //   }
    //   //Last datapoint
    //   else{
    //     if(Math.abs(dataArr[i-1].tstat_read_data.zone_temp - dataArr[i].tstat_read_data.zone_temp) >= 35){
    //       //do nothing
    //       console.log("skipping anomoly data dataanalysis");
    //       continue;
    //     }
    //   }
    //   newDataArr.push(dataArr[i]);
    // }

    if (dataArr.length > 1) {
      //Running lastGoodZoneTemp version of anomaly detection
      var lastGoodZoneTemp = dataArr[0].tstat_read_data.zone_temp
      for (let i = 1; i < dataArr.length - 1; i++) {
        //console.log(dataArr[i].tstat_read_data.zone_temp);

        //Check for under 40 f degrees zone temp anomoly
        try {
          var parsedZoneTemp = parseFloat(dataArr[i].tstat_read_data.zone_temp)
          if (parsedZoneTemp < 40) {
            continue
          }
        } catch (e) {
          console.log('error in dataanalysis removeAnomalousData() ', e)
          continue
        }

        //Check for zone temp anomoly by last good zone temp
        if (Math.abs(lastGoodZoneTemp - dataArr[i].tstat_read_data.zone_temp) >= /*30*/ 12) {
          //do nothing
          console.log('skipping anomoly data dataanalysis')
          continue
        }

        // lastGoodZoneTemp = dataArr[i].tstat_read_data.zone_temp;
        newDataArr.push(dataArr[i])
      }

      // Remove zone temp under 40
      // for (let i = 1; i < dataArr.length-1; i++) {
      //   try{
      //     var parsedZoneTemp = parseFloat(dataArr[i].tstat_read_data.zone_temp);
      //     if(parsedZoneTemp < 40){
      //       continue;
      //     }
      //   }
      //   catch(e){
      //     console.log("error in dataanalysis removeAnomalousData() ", e);
      //     continue;
      //   }
      //   newDataArr.push(dataArr[i]);
      // }
    } else {
      newDataArr = dataArr
    }

    return newDataArr
  }

  drawAllGraphs = ({
    tempData,
    relayData,
    relayMasterData,
    co2Data,
    vocData,
    spsData,
    co2ChartOptions,
    shouldRedraw,
    caller,
  }) => {
    console.log('draw All Graphs caller', caller)

    console.log('drawAllGraphs data', {
      tempData,
      relayData,
      relayMasterData,
      co2Data,
      vocData,
      spsData,
      co2ChartOptions,
      shouldRedraw,
      caller,
    })

    let temperatureGraph
    if (tempData.length) {
      temperatureGraph = this.drawTemperatureGraph(tempData, shouldRedraw)
    }

    let relayGraph
    if (relayData.length) {
      relayGraph = this.drawRelayGraph(relayData, shouldRedraw)
    }

    let relayMasterGraph
    if (relayMasterData.length) {
      relayMasterGraph = this.drawRelayMasterGraph(relayMasterData, shouldRedraw)
    }

    let co2Graph
    if (co2Data.length) {
      co2Graph = this.drawCo2Graph(co2Data, co2ChartOptions, shouldRedraw)
    }

    let vocGraph
    if (vocData.length) {
      vocGraph = this.drawVoCGraph(vocData, shouldRedraw)
    }
    let spsGraph
    if (spsData.length) {
      spsGraph = this.drawSpsGraph(spsData, shouldRedraw)
    }

    if (this.sync === undefined) {
      if (vocGraph && spsGraph) {
        const dataArray =
          !temperatureGraph || !relayGraph
            ? [co2Graph, vocGraph, spsGraph]
            : [
                temperatureGraph,
                relayGraph,
                // relayMasterGraph,
                co2Graph,
                vocGraph,
                spsGraph,
              ]
        this.sync = Dygraph.synchronize(dataArray, {
          range: false,
        })
      } else {
        if (!temperatureGraph && !relayGraph && !co2Graph) {
          return
        }
        console.log('syncing temperatureGraph, relayGraph, relayMasterGraph, co2Graph', this.state.parentDeviceId)
        const syncArray = this.state.parentDeviceId
          ? [temperatureGraph, relayGraph, relayMasterGraph, co2Graph]
          : [temperatureGraph, relayGraph, co2Graph]

        this.sync = Dygraph.synchronize(syncArray, {
          range: false,
        })
      }
    }
  }

  drawTemperatureGraph = (relevantDataArr, shouldRedraw) => {
    console.log('drawTemperatureGraph relevantDataArr', relevantDataArr)
    //  Index 0 is datetime string. We will change it to a date object.
    //  Index 1 is a temperature string that represents a float. We will
    //    change it to an actual float.
    //  Indexes 2 and 3 are heat and cool setpoints respectively, parsed
    //    the same way as the zone temp.
    let newRelevantDataArr = formatTemperatureChartData(relevantDataArr)

    if (this.temperatureGraph === undefined) {
      console.log('Drawing new temperature graph')
      let graphDiv = this.graphTempDiv
      this.temperatureGraph = new Dygraph(graphDiv, newRelevantDataArr, temperatureChartOptions)
    } else {
      console.log('Updating existing temperature graph')
      this.temperatureGraph.updateOptions({ file: newRelevantDataArr })

      if (shouldRedraw) {
        this.temperatureGraph.resetZoom()
      }
    }

    return this.temperatureGraph
  }

  drawSpsGraph = (relevantDataArr, shouldRedraw) => {
    console.log('drawSpsGraph relevantDataArr', relevantDataArr)
    //  Index 0 is datetime string. We will change it to a date object.
    //  Index 1 is a temperature string that represents a float. We will
    //    change it to an actual float.
    //  Indexes 2 and 3 are heat and cool setpoints respectively, parsed
    //    the same way as the zone temp.
    let newRelevantDataArr = relevantDataArr.map((dataItem, i) => {
      let resultItem = []
      resultItem[0] = new Date(dataItem[0])
      resultItem[1] = parseFloat(dataItem[1])
      resultItem[2] = parseFloat(dataItem[2])
      return resultItem
    })

    if (this.spsGraph === undefined) {
      console.log('Drawing new spsGraph graph', fineParticulatesChartOptions)
      let graphDiv = this.graphFineParticulatesDiv
      this.spsGraph = new Dygraph(graphDiv, newRelevantDataArr, fineParticulatesChartOptions)
    } else {
      console.log('Updating existing spsGraph graph')
      this.spsGraph.updateOptions({ file: newRelevantDataArr })

      if (shouldRedraw) {
        this.spsGraph.resetZoom()
      }
    }

    return this.spsGraph
  }

  drawVoCGraph = (relevantDataArr, shouldRedraw) => {
    console.log('drawVoCGraph relevantDataArr', relevantDataArr)
    //  Index 0 is datetime string. We will change it to a date object.
    //  Index 1 is a temperature string that represents a float. We will
    //    change it to an actual float.
    //  Indexes 2 and 3 are heat and cool setpoints respectively, parsed
    //    the same way as the zone temp.
    let newRelevantDataArr = formatVolatileGasChartData(relevantDataArr)

    if (this.vocGraph === undefined) {
      console.log('Drawing new volatileGasChartOptions graph', volatileGasChartOptions)
      let graphDiv = this.graphVolatileGasDiv
      this.vocGraph = new Dygraph(graphDiv, newRelevantDataArr, volatileGasChartOptions)
    } else {
      console.log('Updating existing volatileGasChartOptions graph')
      this.vocGraph.updateOptions({ file: newRelevantDataArr })

      if (shouldRedraw) {
        this.vocGraph.resetZoom()
      }
    }

    return this.vocGraph
  }
  drawRelayMasterGraph = (relevantDataArr) => {
    let newRelevantDataArr = formatRelayChartData(relevantDataArr)
    if (this.relayMasterGraph === undefined) {
      let graphDiv = this.graphRelayMasterDiv
      this.relayMasterGraph = new Dygraph(graphDiv, newRelevantDataArr, getRelayChartOptions('legendDivRelayMaster'))
    } else {
      console.log('newRelevantDataArr', newRelevantDataArr)
      this.relayMasterGraph.updateOptions({ file: newRelevantDataArr })
    }

    return this.relayMasterGraph
  }
  drawRelayGraph = (relevantDataArr, shouldRedraw) => {
    let newRelevantDataArr = formatRelayChartData(relevantDataArr)
    // console.log('drawRelayGraph newRelevantDataArr', newRelevantDataArr)
    if (this.relayGraph === undefined) {
      let graphDiv = this.graphRelayDiv
      this.relayGraph = new Dygraph(graphDiv, newRelevantDataArr, getRelayChartOptions('legendDivRelay'))
    } else {
      console.log('update drawRelayGraph data', newRelevantDataArr)
      console.log('update drawRelayGraph this.relayGraph', this.relayGraph)
      this.relayGraph.updateOptions({ file: newRelevantDataArr })
      if (shouldRedraw) {
        this.relayGraph.resetZoom()
      }
    }

    return this.relayGraph
  }

  drawCo2Graph = (relevantDataArr, chartOptions, shouldRedraw) => {
    console.log('mode', chartOptions)
    let newRelevantDataArr = formatCo2Data(relevantDataArr, chartOptions)
    // console.log('newRelevantDataArr', newRelevantDataArr);
    if (this.co2Graph === undefined) {
      let graphDiv = this.graphCo2Div
      console.log('DRAW co2 GRAPH', newRelevantDataArr)
      this.co2Graph = new Dygraph(graphDiv, newRelevantDataArr, chartOptions.options)
    } else {
      // console.log('UPDATE co2 GRAPH', newRelevantDataArr)
      this.co2Graph.updateOptions({
        file: newRelevantDataArr,
      })
    }

    return this.co2Graph
  }

  render() {
    const isNonThermastat = checkIfNonThermastat(this.props.deviceInfo, this.state.allData)
    const showThermaStatGridItem = { display: isNonThermastat ? 'none' : 'initial' }
    const showNonThermaStatGridItem = { display: isNonThermastat ? 'initial' : 'none' }
    const co2_cnfg_dcv = this.state.co2_cnfg_dcv
    console.log('this.state.allData bulk', this.state.allData)
    let renderMasterRelays
    if (this.state.parentDeviceId) {
      renderMasterRelays = (
        <Col md={6} sm={6} xs={12} style={showThermaStatGridItem}>
          <XPanel>
            <XPanel.Title title='Master Relays Over Time'></XPanel.Title>
            <XPanel.Content>
              <div id='legendDivRelayMaster' className='legend' />
              <div style={{ minHeight: '320px' }} ref={this.setGraphRelayMasterDiv} />
            </XPanel.Content>
          </XPanel>
        </Col>
      )
    }

    return (
      <div>
        <Row>
          <Col md={4} sm={6} xs={6}>
            <span style={{ fontSize: '2em', marginRight: '8px' }}>
              {this.state.deviceName ? this.state.deviceName : 'loading...'}
            </span>
            <Spinner active={this.state.apiCalling} />
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} xs={4}>
            <div>
              <b>Mac Address: {this.state.deviceName ? this.state.deviceMac : 'loading...'}</b>
            </div>
            <div>
              <b>Data Trending</b>
            </div>
          </Col>
          <Col md={4} sm={4} xs={4}>
            <PastDatePick
              viewMode={this.state.viewMode}
              handleChangeViewMode={this.handleChangeViewMode}
              getPastDataAndDrawGraphs={this.getPastDataAndDrawGraphs}
            />
          </Col>
          <Col md={4} sm={4} xs={4}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <DownloadCSVButton
                isLoading={this.state.apiCalling}
                data={this.state.allData}
                pastData={this.state.allPastData}
                macId={this.state.deviceMac}
                view={this.state.viewMode}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12} style={showThermaStatGridItem}>
            <XPanel>
              <XPanel.Title title='Temperature Over Time'></XPanel.Title>
              <XPanel.Content>
                <div id='legendDivTemp' className='legend' />
                <div style={{ minHeight: '320px' }} ref={this.setGraphTempDiv}></div>
              </XPanel.Content>
            </XPanel>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <XPanel>
              <XPanel.Title title='Ventilation Over Time'></XPanel.Title>
              <XPanel.Content>
                <div style={{ display: 'flex' }}>
                  {co2_cnfg_dcv && co2_cnfg_dcv.mode && (
                    <div style={{ marginRight: '8px' }}>Mode: {modeMap[co2_cnfg_dcv.mode]}</div>
                  )}
                  {co2_cnfg_dcv && co2_cnfg_dcv.low_ppm && (
                    <div style={{ marginRight: '8px' }}>Low PPM: {co2_cnfg_dcv.low_ppm}</div>
                  )}
                  {co2_cnfg_dcv && co2_cnfg_dcv.high_ppm && (
                    <div style={{ marginRight: '8px' }}>High PPM: {co2_cnfg_dcv.high_ppm}</div>
                  )}
                </div>
                <div id='legendDivCo2' className='legend' />
                <div style={{ minHeight: '320px' }} ref={this.setGraphCo2Div} />
              </XPanel.Content>
            </XPanel>
          </Col>
          <Col md={6} sm={6} xs={12} style={showNonThermaStatGridItem}>
            <XPanel>
              <XPanel.Title title='Volatile gases over time'></XPanel.Title>
              <XPanel.Content>
                <div id='legendDivVoc' className='legend' />
                <div style={{ minHeight: '320px' }} ref={this.setGraphVoCDiv} />
              </XPanel.Content>
            </XPanel>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} xs={12} style={showThermaStatGridItem}>
            <XPanel>
              <XPanel.Title title='Relays Over Time'></XPanel.Title>
              <XPanel.Content>
                <div id='legendDivRelay' className='legend' />
                <div style={{ minHeight: '320px' }} ref={this.setGraphRelayDiv} />
              </XPanel.Content>
            </XPanel>
          </Col>
          {renderMasterRelays}
          <Col md={6} sm={6} xs={12} style={showNonThermaStatGridItem}>
            <XPanel>
              <XPanel.Title title='Fine particulates over time'></XPanel.Title>
              <XPanel.Content>
                <div id='legendDivSps' className='legend' />
                <div style={{ minHeight: '320px' }} ref={this.setGraphSpsDiv} />
              </XPanel.Content>
            </XPanel>
          </Col>
          <Col md={6} sm={6} xs={12}></Col>
        </Row>
        <CommentsSection itemId={this.state.deviceMac} />
      </div>
    )
  }
}


/**
 * URL Regex pattern
 * @type {RegExp}
 */
const URL_REGEX = /^(https?)?(?:[\:\/]*)([a-z0-9\.-]*)(?:\:(\d+))?(\/[^?#]*)?(?:\?([^#]*))?(?:#(.*))?$/i

/**
 * @typedef {object} UrlDetails
 * @property {string} href
 * @property {string} protocol
 * @property {string} hostname
 * @property {string} port
 * @property {string} path
 * @property {string} search
 * @property {string} hash
 */

/**
 * Zero dependency backward compatible url parser
 * @param {string} url
 * @returns {UrlDetails}
 */
function parseUrl(url = '') {
  const match = url.match(URL_REGEX)
  return {
    href: match[0] || '',
    protocol: match[1] || '',
    hostname: match[2] || '',
    port: match[3] || '',
    pathname: match[4] || '',
    search: match[5] || '',
    hash: match[6] || '',
  }
}

function getParentId() {
  const originalUrl = parseUrl(window.location.href)
  const testUrl = `http://fake.com${originalUrl.hash}`
  const url = new URL(testUrl)

  // Get search parameters
  const searchParams = url.searchParams
  const parentDeviceId = searchParams.get('parent')
  return parentDeviceId
}

export default DataAnalysis
